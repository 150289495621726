// TEMPORARY UNTIL REAL API INTERFACE
import { Action } from "./common";
import { NodeType } from "./node";

export enum SolutionActionType {
  app = "app",
  callback = "callback",
  chatbot = "chatbot",
  contactForm = "contactForm",
  displayContent = "displayContent",
  downloadDocument = "downloadDocument",
  email = "email",
  faq = "faq",
  fax = "fax",
  form = "form",
  liveChat = "liveChat",
  physicalLocation = "physicalLocation",
  postalMail = "postalMail",
  ivrCallOriented = "ivrCallOriented",
  ivrCallHead = "ivrCallHead",
  internalFAQ = "internalFAQ",
  store = "store",
  contextualizedRedirection = "contextualizedRedirection",
  customerArea = "customerArea",
  iFrame = "iFrame",
}

type SolutionType = {
  id: string;
  type: NodeType.solution;
  name: string;
  title: string | null;
  integrationType: {
    voice: boolean;
    widget: boolean;
    hyperlink: boolean;
  };
  enabled: boolean;
  delay: number;
  timetable: boolean;
  view: string;
  parentId: string;
  position: number;
  actionType: SolutionActionType;
} & Action;

export default SolutionType;
