import { parseUserAgent } from "../../../common/userAgent";
import {
  Action,
  ActionTypeAppOS,
  DynamicUrls,
  PushWrapperFn,
  SolutionActionType,
} from "../../../types";
import {
  checkIfUrlActionType,
  getOsFromParsedUserAgent,
  handleContextualizedRedirection,
  handleEmailAction,
  handleIFrameAction,
  handleInternalFaqAction,
  handleIvrAction,
  handlePostalMailAction,
  handleSolutionAnalytic,
  handleStoreOrAppAction,
  handleUrlAction,
} from "../../helpers";
import { handleCodeAction } from "../router/handleCodeAction";
import { handleLiveChatAction } from "../router/handleLiveChatAction";

export type HandleClickSolutionArgs = {
  actionType: SolutionActionType;
  shortCode: string | undefined;
  scenarioId: string | undefined;
  id: string;
  routerPush: PushWrapperFn;
  branch: string;
  dynamicUrls: DynamicUrls;
  isAutomaticClick?: boolean;
} & Action;

const handleClickSolution = async ({
  actionType,
  url,
  shortCode,
  scenarioId,
  to,
  subject,
  body,
  cc,
  bcc,
  pageKey,
  id,
  iosLink,
  androidLink,
  appStoreLink,
  playStoreLink,
  phoneNumber,
  location,
  openType,
  branch,
  routerPush,
  dynamicUrls,
  isAutomaticClick,
  code,
}: HandleClickSolutionArgs) => {
  let parsedUserAgent: ReturnType<typeof parseUserAgent> | undefined;
  const isIvrSolutionAction =
    actionType === SolutionActionType.ivrCallHead ||
    actionType === SolutionActionType.ivrCallOriented;
  const isAppOrStoreSolutionAction =
    actionType === SolutionActionType.app || actionType === SolutionActionType.store;
  try {
    parsedUserAgent = parseUserAgent(navigator.userAgent);
  } catch {
    parsedUserAgent = undefined;
  }
  const isNotAMobile = parsedUserAgent?.platform?.type !== 2;
  if (!scenarioId && !shortCode) return;
  let solutionHasBeenExecuted = false;
  if (checkIfUrlActionType({ actionType })) {
    solutionHasBeenExecuted = handleUrlAction({ url, openInCurrentTab: isAutomaticClick });
  } else if (actionType === SolutionActionType.contextualizedRedirection) {
    solutionHasBeenExecuted = handleContextualizedRedirection({
      branch,
      location,
      openType,
      dynamicUrls,
    });
  } else if (actionType === SolutionActionType.email) {
    solutionHasBeenExecuted = handleEmailAction({ to, subject, body, cc, bcc });
  } else if (actionType === SolutionActionType.internalFAQ) {
    solutionHasBeenExecuted = await handleInternalFaqAction({
      pageKey,
      shortCode,
      scenarioId,
      routerPush,
    });
  } else if (isIvrSolutionAction) {
    solutionHasBeenExecuted = await handleIvrAction({
      id,
      shortCode,
      scenarioId,
      routerPush,
      phoneNumber,
      toPageKey: `solution-${id}`,
    });
  } else if (actionType === SolutionActionType.postalMail) {
    solutionHasBeenExecuted = await handlePostalMailAction({
      id,
      shortCode,
      scenarioId,
      routerPush,
      toPageKey: `solution-${id}`,
    });
  } else if (actionType === SolutionActionType.liveChat) {
    if (code)
      solutionHasBeenExecuted = await handleCodeAction({
        id,
        shortCode,
        scenarioId,
        routerPush,
      });

    handleLiveChatAction({ url, openInCurrentTab: isAutomaticClick });

    /* Two cases are possible, if it is an Iadvize solution, the helper above won't do anything,
      but we still have to send the analytics, so solutionHasBeenExecuted must be set to true.  */
    solutionHasBeenExecuted = true;
  } else if (isAppOrStoreSolutionAction) {
    solutionHasBeenExecuted = await handleStoreOrAppAction({
      isNotAMobile,
      id,
      shortCode,
      scenarioId,
      actionType: actionType as SolutionActionType.app | SolutionActionType.store,
      routerPush,
      appStoreLink,
      playStoreLink,
      androidLink,
      iosLink,
      toPageKey: `solution-${id}`,
      os: (parsedUserAgent
        ? getOsFromParsedUserAgent(parsedUserAgent)
        : "other") as ActionTypeAppOS,
    });
  } else if (actionType === SolutionActionType.iFrame) {
    solutionHasBeenExecuted = await handleIFrameAction({
      id,
      shortCode,
      scenarioId,
      routerPush,
      toPageKey: `solution-${id}`,
    });
  } else {
    console.error("Invalid ActionType provided");
  }
  if (solutionHasBeenExecuted)
    await handleSolutionAnalytic({ type: actionType, solutionId: id, shortCode });
};

export default handleClickSolution;
