import {
  USER_AGENT_BROWSERS,
  USER_AGENT_ENGINES,
  USER_AGENT_OSS,
  USER_AGENT_PLATFORNS,
  USER_AGENT_VENDORS,
} from "./constants";
import {
  UserAgentBrowser,
  UserAgentEngine,
  UserAgentOs,
  UserAgentPlatform,
  UserAgentVendor,
} from "./types";

export function getVersion(version: string | null | undefined) {
  if (version == null) {
    return null;
  }

  const transformedVersion = version.trim();

  if (transformedVersion === "") {
    return null;
  }

  return transformedVersion;
}

export function getBrowserType(name: string | null | undefined) {
  if (name == null) {
    return UserAgentBrowser.Unknown;
  }

  return USER_AGENT_BROWSERS[name as keyof typeof USER_AGENT_BROWSERS] ?? UserAgentBrowser.Unknown;
}

export function getEngineType(name: string | null | undefined) {
  if (name == null) {
    return UserAgentEngine.Unknown;
  }

  return USER_AGENT_ENGINES[name as keyof typeof USER_AGENT_ENGINES] ?? UserAgentEngine.Unknown;
}

export function getOsType(name: string | null | undefined) {
  if (name == null) {
    return UserAgentOs.Unknown;
  }

  return USER_AGENT_OSS[name as keyof typeof USER_AGENT_OSS] ?? UserAgentOs.Unknown;
}

export function getPlatformType(name: string | null | undefined) {
  if (name == null) {
    return UserAgentPlatform.Unknown;
  }

  return (
    USER_AGENT_PLATFORNS[name as keyof typeof USER_AGENT_PLATFORNS] ?? UserAgentPlatform.Unknown
  );
}

export function getVendorType(name: string | null | undefined) {
  if (name == null) {
    return UserAgentVendor.Unknown;
  }

  return USER_AGENT_VENDORS[name as keyof typeof USER_AGENT_VENDORS] ?? UserAgentVendor.Unknown;
}

export function cleanupValue(value: string | undefined | null) {
  if (value == null) {
    return null;
  }

  return value.trim().toLowerCase();
}
