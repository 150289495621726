import { Language } from "../types/language";

export function readFile(file: File): Promise<string | ArrayBuffer | null | undefined> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event?.target?.result);
    };
    reader.onerror = (err) => reject(err);
    reader.readAsDataURL(file);
  });
}

export const getLanguage = (lang: string | undefined): Language => {
  return lang === "fr" ? Language.fr : Language.en;
};
