import { forwardRef } from "react";
import {
  TablePagination as MUITablePagination,
  TablePaginationProps as MUITablePaginationProps,
} from "@mui/material";

export type TablePaginationProps = MUITablePaginationProps;

export const TablePagination = forwardRef<unknown, TablePaginationProps>((props, ref) => {
  return <MUITablePagination ref={ref} {...props} />;
});
