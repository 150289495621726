import { useTheme } from "@mui/system";
import type { SVGAttributes } from "react";
import { forwardRef } from "react";
import type { ThemeType } from "../../theme";

type LogoProps = SVGAttributes<SVGSVGElement> & {
  height?: number;
  inverted?: boolean;
  small?: boolean;
};

export const Logo = forwardRef<SVGSVGElement, LogoProps>(
  ({ height = 30, inverted, small, ...svgProps }, ref) => {
    const theme: ThemeType = useTheme();

    if (!theme.palette.secondary.main || !theme.palette.primary.main) {
      // eslint-disable-next-line no-console
      console.error("Missing theme");
    }

    return (
      <svg ref={ref} viewBox={`0 0 ${small ? 156 : 668} 156`} height={height} {...svgProps}>
        <path
          fill={theme.palette.secondary.main || "black"}
          clipRule="evenodd"
          fillRule="evenodd"
          d="M156 68a78 78 0 0 0-30-51A78 78 0 0 0 0 75v8h1l13-6 1-1a63 63 0 0 1 55-61 63 63 0 0 1 71 55 64 64 0 0 1-46 69l10-79c0-3-1-6-4-7a7 7 0 0 0-7 0L15 96a7 7 0 0 0-4 8 7 7 0 0 0 7 5c1 0 25 1 45 17l4 4 1 1 1-1 3-14v-1a87 87 0 0 0-32-17l48-26-9 75a7 7 0 0 0 8 8h1a78 78 0 0 0 68-87"
        />
        {small || (
          <path
            fill={inverted ? "white" : theme.palette.primary.main || "black"}
            d="M286 37c-4 0-8 3-8 8s4 8 8 8 8-3 8-8-4-8-8-8zm-34 1c-2 0-3 1-3 3v27c-4-5-13-9-19-9-16 0-28 10-28 30 0 18 12 29 29 29 7 0 13-3 18-9v4a4 4 0 0 0 4 3h6c2 0 3-1 3-3V41c0-2-1-3-3-3h-7zm135 0c-2 0-4 1-4 3v72a4 4 0 0 0 4 3h6c2 0 4-1 4-3V41c0-2-2-3-4-3h-6zm195 21c-16 0-30 10-30 30 0 19 14 29 30 29a28 28 0 0 0 18-6c2-1 2-4 1-5l-5-5h-4c-3 2-7 3-10 3-9 0-16-6-16-16s7-17 16-17a17 17 0 0 1 9 3 4 4 0 0 0 5-1l4-4c1-2 1-4-1-5a26 26 0 0 0-17-6zm57 0c-18 0-30 12-30 29 0 18 12 30 31 30a36 36 0 0 0 21-7c1-1 2-3 0-5l-4-4h-4c-4 2-9 3-13 3-10 0-16-5-17-12h44v-5c0-19-11-29-28-29zm-304 0c-16 0-28 10-28 29 0 20 12 30 28 30 6 0 15-3 18-10l1 5a4 4 0 0 0 3 3h6c2 0 4-1 4-3V64c0-2-2-3-3-3h-7a3 3 0 0 0-3 3l-1 4c-3-5-10-9-18-9zm108 1a28 28 0 0 0-2 0c-17 0-28 13-28 29s10 29 29 29c18 0 29-13 29-29a28 28 0 0 0-28-29zm73 0a25 25 0 0 0-19 8v-4c0-2-2-3-4-3h-5c-2 0-4 1-4 3v49a4 4 0 0 0 4 3h6c2 0 4-1 4-3V87c0-8 6-15 14-15 9 0 14 7 14 15v26a4 4 0 0 0 3 3h7c2 0 3-1 3-3V87c0-17-10-27-23-27zm-234 1a4 4 0 0 0-3 3v49c0 2 1 3 3 3h7c2 0 4-1 4-3V64c0-2-2-3-4-3h-7zm357 10c8 0 14 4 15 11h-30c2-7 8-11 15-11zm-302 1c11 0 16 8 16 17 0 8-5 16-16 16-9 0-17-6-17-16 0-11 8-17 17-17zm105 0c9 0 15 8 15 17 0 8-5 16-15 16s-16-8-16-16c0-9 6-17 16-17zm-210 0c8 0 16 7 16 17s-8 16-16 16c-9 0-16-7-16-16 0-11 7-17 16-17z"
          />
        )}
      </svg>
    );
  }
);
