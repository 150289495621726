export enum ScenarioVersionItemTypeEnum {
  Page = 1,
  SingleSolution = 2,
}

export type PageType = {
  id: string;
  key: string;
  type: ScenarioVersionItemTypeEnum;
  name: string;
  child: Node[];
};
