import { SolutionActionType } from "./solutions";

export enum ScenarioVersionItemTypeEnum {
  Page = 1,
  SingleSolution = 2,
}

export type ScenarioNavigationItem = {
  id: string;
  name: string;
  fromKey: string;
  displayTitle: string;
  position: number;
  blockPosition: number | null;
  to: ScenarioNavigationItemTo | null;
};

export type ScenarioNavigationItemTo = {
  type: ScenarioVersionItemTypeEnum;
  key: string;
  id: string;
  solution: ScenarioNavigationSolution | null;
};

type ScenarioNavigationSolution = {
  id: string;
  actionType: SolutionActionType;
} & (
  | ActionTypeApp
  | ActionTypeUrl
  | ActionTypeContextualizedRedirection
  | ActionTypeEmail
  | ActionTypeInternalFaq
  | ActionTypeLiveChat
  | ActionTypePostalMail
  | ActionTypeStore
);

type ActionTypeApp = {
  iosLink?: string;
  androidLink?: string;
  introductionText?: string;
  os?: ActionTypeAppOS;
};

enum ActionTypeAppOS {
  iOS = "iOS",
  Android = "Android",
  other = "other",
}

type ActionTypeUrl = {
  url?: string;
};

type ActionTypeContextualizedRedirection = {
  openType?: string;
  location?: string;
};

type ActionTypeEmail = {
  to?: string;
  subject?: string;
  body?: string;
  cc?: string;
  bcc?: string;
};

type ActionTypeInternalFaq = {
  pageKey?: string;
};

type ActionTypeLiveChat = {
  url?: string;
  sid?: string;
  onlineId?: string;
  offlineId?: string;
  onlineContent?: string;
  offlineContent?: string;
};

type ActionTypePostalMail = {
  address1?: string;
  address2?: string;
  postalCode?: string;
  city?: string;
  country?: string;
};

type ActionTypeStore = {
  playStoreLink?: string;
  appStoreLink?: string;
  os?: ActionTypeAppOS;
};
