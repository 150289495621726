import { FC, useEffect } from "react";
import { ScenarioData } from "..";
import { ScenarioVersionChildSolutionActionLiveChat } from "@dial-once/ich-seed";
import Script from "next/script";

export type Props = {
  scenarioData: ScenarioData;
  forceRemount: number;
};

const CodeNode: FC<Props> = ({ scenarioData, forceRemount }) => {
  const code = (scenarioData.solution as ScenarioVersionChildSolutionActionLiveChat).code;

  useEffect(() => {
    return () => {
      const scriptElement = document.getElementById(`liveChatScript-${forceRemount}`);
      if (scriptElement) {
        scriptElement.remove();
      }
    };
  }, [forceRemount]);

  return (
    <>
      {code && (
        <Script
          // The dynamic id allows the script to be re-executed at each onClick event on the solution button
          id={`liveChatScript-${forceRemount}`}
          dangerouslySetInnerHTML={{
            __html: code,
          }}
        />
      )}
    </>
  );
};

export default CodeNode;
