import { Box } from "@mui/material";
import { FC, PropsWithChildren, memo } from "react";

const PageContainer: FC<PropsWithChildren> = memo(({ children }) => {
  // If isInIframe = true, we are in a widget and we can display the closeButton
  return <Box id="ich-content">{children}</Box>;
});

PageContainer.displayName = "PageContainer";
export default PageContainer;
