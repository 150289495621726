import { SolutionActionType } from "../../../types";
import { ScenarioAnalyticsDocumentActionType } from "../../../types/scenarioAnalytics";
import { postScenarioDetails } from "../requests";

type HandleSolutionAnalyticArgs = {
  type: SolutionActionType;
  solutionId: string;
  shortCode?: string;
};

export const handleSolutionAnalytic = async ({
  type,
  solutionId,
  shortCode,
}: HandleSolutionAnalyticArgs) => {
  await postScenarioDetails({
    type: ScenarioAnalyticsDocumentActionType.Solution,
    solutionType: type,
    solutionId,
    shortCode,
  });
};
