export const borderRadiusConverter = {
  xs: 1, // 4px
  sm: 2, // 8px
  md: 3, // 12px
  lg: 4, // 16px
  full: 9999,
};

export const borderColorConverter = {
  light: "grey.300",
  dark: "grey.600",
};
