import { getInSessionStorage } from "./analytics";

type AdditionalValues = {
  [key: string]: string | undefined;
  scenarioSessionId?: string;
  solutionId?: string;
};

// Replace every {{value}} in url by the corresponding value in passed value or session storage
export const parseIframeURL = (url: string, additionalValues: AdditionalValues = {}) => {
  const curlyBracesRegexp = /{{([^}]*)}}/g;
  const missingValues: string[] = [];

  const parsedURL = url.replace(curlyBracesRegexp, (_, capture) => {
    if (additionalValues[capture]) {
      return additionalValues[capture] ?? "";
    } else {
      const value = getInSessionStorage(capture);
      if (!value) {
        missingValues.push(capture);
      }
      return value ?? "";
    }
  });

  if (missingValues.length > 0) {
    // eslint-disable-next-line no-console
    console.error(
      `Missing values in iframe template URL: ${url}, didn't find values: ${missingValues.join(
        ", "
      )}`
    );
    return null;
  }

  return parsedURL;
};

type IframePreviewConfig = {
  [iframeId: string]: { [paramKey: string]: string };
};

export const getIframePreviewParameters = (solutionId: string) => {
  const iframeConfigs: IframePreviewConfig = JSON.parse(getInSessionStorage("iframe-ids") ?? "{}");

  return iframeConfigs[solutionId] ?? {};
};
