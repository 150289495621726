import { Box, IconButton, Text, theme } from "@do/ui-do";
import { Typography } from "@mui/material";
import { FC } from "react";
import { ActionTypeAppOS } from "..";
import { PhoneIcon } from "../ui";

export type PhoneNumberSolutionProps = {
  className?: string;
  title: string;
  phoneNumber: string;
  userOs: ActionTypeAppOS;
  pinCode?: string;
  isDigicall: boolean;
  isFastDialEnabled: boolean;
};

const PhoneNumberSolutionCard: FC<PhoneNumberSolutionProps> = ({
  className,
  title,
  phoneNumber,
  userOs,
  pinCode,
  isDigicall,
  isFastDialEnabled,
}) => {
  const isMobileDevice = userOs !== ActionTypeAppOS.other;
  const separator = userOs === ActionTypeAppOS.iOS ? ";postd=" : ";";
  const phoneSuffix = pinCode && isDigicall ? `${separator}${pinCode}` : "";
  const phoneHref = isMobileDevice ? `tel:${phoneNumber}${phoneSuffix}` : "";

  if (isMobileDevice && isFastDialEnabled) {
    window.open(phoneHref, "_blank");
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="flex-start"
      margin="0 auto"
      pt={theme.spacing(4)}
      width="100%"
      height="100vh"
      className={className}
    >
      <Typography
        variant="h6"
        textAlign="center"
        color="primary"
        fontWeight="bolder"
        pb={theme.spacing(4)}
        fontSize="1.25rem"
      >
        {title}
      </Typography>
      <Box display="flex" flexDirection="column" alignItems="center">
        <IconButton
          href={phoneHref}
          target="_blank"
          sx={{
            width: "min-content",
            background: theme.palette.background.paper,
            gap: 4,
            fontWeight: "bold",
            display: "flex",
            justifyContent: "center",
            px: 6,
            py: 2,
            borderRadius: 1,
            boxShadow: 5,
          }}
        >
          <PhoneIcon />
          <Box
            sx={{
              maxWidth: 400,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {phoneNumber}
          </Box>
        </IconButton>
        {pinCode && !isMobileDevice && isDigicall && (
          <>
            <Text mt={2} lineHeight={2} padding={0} textAlign="center">
              Une fois l&apos;appel établi, veuillez entrer le code suivant :
            </Text>
            <Text lineHeight={2} padding={0} sx={{ fontWeight: "bold" }}>
              {pinCode}
            </Text>
          </>
        )}
      </Box>
    </Box>
  );
};

export default PhoneNumberSolutionCard;
