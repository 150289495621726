import { forwardRef } from "react";
import { TableRow as MUITableRow, TableRowProps as MUITableRowProps } from "@mui/material";
import { TableCell } from "./TableCell";
import { classNames } from "../../../helpers";

export type TableRowProps = MUITableRowProps & {
  emptyRows?: number;
  size?: "small" | "medium";
  colSpan?: number;
  striped?: boolean;
  borderless?: boolean;
};

export const TableRow = forwardRef<HTMLTableRowElement, TableRowProps>(
  (
    {
      borderless = false,
      children,
      className,
      colSpan = 1,
      emptyRows = 0,
      size = "medium",
      striped = false,
      sx,
      ...props
    },
    ref
  ) => {
    return (
      <MUITableRow
        ref={ref}
        className={classNames(striped && "striped", borderless && "borderless", className)}
        sx={{
          height: (size === "small" ? 33 : 53) * emptyRows,
          "&.striped:nth-of-type(odd)": {
            backgroundColor: "grey.50",
          },
          ...sx,
        }}
        {...props}
      >
        {emptyRows > 0 ? <TableCell colSpan={colSpan} /> : children}
      </MUITableRow>
    );
  }
);
