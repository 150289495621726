import { Stack as MUIStack, StackProps as MUIStackProps } from "@mui/material";
import { size as polishedSize } from "polished";
import { forwardRef } from "react";
import { borderColorConverter, borderRadiusConverter, elevationConverter } from "../../constants";
import { BorderColor, BorderRadius, Elevation } from "../../types";

export type StackProps = MUIStackProps & {
  rounded?: BorderRadius;
  border?: boolean;
  borderTheme?: BorderColor;
  elevation?: Elevation;
  size?: number;
};

export const Stack = forwardRef<HTMLDivElement, StackProps>(
  ({ rounded, border = false, borderTheme = "light", elevation, size, ...props }, ref) => {
    return (
      <MUIStack
        ref={ref}
        {...(border && {
          border: 1,
          borderColor: borderColorConverter[borderTheme],
        })}
        borderRadius={rounded ? borderRadiusConverter[rounded] : undefined}
        boxShadow={elevation ? elevationConverter[elevation] : undefined}
        {...(size ? polishedSize(size) : {})}
        {...props}
      />
    );
  }
);
