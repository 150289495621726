import { Checkbox } from "@mui/material";
import MenuItem, { MenuItemProps } from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import { SxProps } from "@mui/system/styleFunctionSx/styleFunctionSx";
import React, { forwardRef, RefObject } from "react";
import type { MenuItemData } from ".";
import { updateCheckboxes } from "../../../helpers/updateCheckboxes";

const StyledMenuItem = styled(MenuItem)({
  display: "flex",
  justifyContent: "space-between",
  maxWidth: "400px",
  paddingLeft: "8px",
  paddingRight: "8px",
});

const StyledTypography = styled(Typography)({
  overflow: "hidden",
  paddingLeft: "8px",
  paddingRight: "8px",
  textAlign: "left",
  textOverflow: "ellipsis",
});

const FlexBox = styled(Box)({
  display: "flex",
});

type IconMenuItemProps = {
  MenuItemProps?: MenuItemProps;
  className?: string;
  disabled?: boolean;
  label?: string;
  renderLabel?: () => React.ReactNode;
  leftIcon?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  ref?: RefObject<HTMLLIElement>;
  rightIcon?: React.ReactNode;
  checked?: boolean;
  setItems?: React.Dispatch<React.SetStateAction<MenuItemData[]>>;
  sx?: SxProps;
  path: number[];
  indeterminate?: boolean;
};

export const IconMenuItem = forwardRef<HTMLLIElement, IconMenuItemProps>(function IconMenuItem(
  {
    checked,
    className,
    indeterminate,
    label,
    leftIcon,
    MenuItemProps,
    path,
    renderLabel,
    rightIcon,
    setItems,
    onClick,
    ...props
  },
  ref
) {
  const toggleItem = () => {
    if (setItems) {
      setItems((prev) => updateCheckboxes(prev, path, !checked));
    }
  };

  return (
    <StyledMenuItem
      {...MenuItemProps}
      {...props}
      className={className}
      onClick={(event) => {
        toggleItem();
        onClick && onClick(event);
      }}
      ref={ref}
    >
      {checked !== undefined && (
        <Checkbox
          checked={checked}
          indeterminate={indeterminate}
          onChange={() => toggleItem()}
          sx={{ padding: 0 }}
        />
      )}

      <FlexBox flex="1" overflow="hidden">
        {leftIcon}
        {renderLabel ? renderLabel() : <StyledTypography>{label}</StyledTypography>}
      </FlexBox>

      {rightIcon}
    </StyledMenuItem>
  );
});
