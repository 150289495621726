import { FC, useEffect, useState, useRef, ReactElement, ReactNode, PropsWithChildren } from "react";

type Props = {
  children: ReactNode | ReactElement[];
  delay: number;
  className?: string;
} & PropsWithChildren;

const DelayDisplay: FC<Props> = ({ children, delay, className }) => {
  const [display, setDisplay] = useState<boolean>(delay <= 0);
  const timeOutRef = useRef(0);

  useEffect(() => {
    if (display) return;
    timeOutRef.current = window.setTimeout(() => {
      setDisplay(true);
    }, delay);
    return () => {
      if (timeOutRef.current) clearTimeout(timeOutRef.current);
    };
  }, [delay, display]);

  return display ? <div className={className}>{children}</div> : null;
};

export default DelayDisplay;
