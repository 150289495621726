import React, { forwardRef } from "react";
import {
  ButtonGroup as MUIButtonGroup,
  ButtonGroupProps as MUIButtonGroupProps,
} from "@mui/material";
import { ButtonProps } from "./Button";
import { ButtonColor } from "../../types";

export type ButtonGroupProps = Omit<MUIButtonGroupProps, "size"> & {
  size?: "sm" | "md" | "lg";
  color?: Omit<ButtonColor, "gradient">;
};

export const ButtonGroup = forwardRef<HTMLDivElement, ButtonGroupProps>(
  ({ children, size, ...props }, ref) => {
    const childrenWithSize = React.Children.map(children, (child) => {
      if (React.isValidElement<ButtonProps>(child) && size) {
        return React.cloneElement(child, { size });
      }
      return child;
    });

    return (
      <MUIButtonGroup ref={ref} {...props}>
        {childrenWithSize}
      </MUIButtonGroup>
    );
  }
);
