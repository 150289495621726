import { FC } from "react";
import { Box } from "@mui/material";
import { ActionApp, ActionStore, SolutionActionType } from "../../types";
import { AppStoreLogo, PlayStoreLogo } from "../ui";

const containerStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: "0 auto",
  padding: "2rem",
  width: "100%",
  height: "100vh",

  "& > :not(:last-child)": {
    marginBottom: "2rem",
  },
};

const titleStyle = {
  fontSize: "1.2em",
  fontWeight: "bold",
};

const iconsStyle = {
  width: "20rem",
  maxWidth: 400,
};

export type Props = {
  onClick: (os: "Android" | "iOS") => void;
  actionType: SolutionActionType.app | SolutionActionType.store;
} & ActionApp &
  ActionStore;

const IntermediateOpenAppNode: FC<Props> = ({
  actionType,
  onClick,
  iosLink,
  introductionText,
  androidLink,
  playStoreLink,
  appStoreLink,
}) => {
  const title =
    actionType === SolutionActionType.app
      ? introductionText
      : "Choisissez le store qui vous convient";
  const iosLinkHref = actionType === SolutionActionType.app ? iosLink : appStoreLink;
  const androidLinkHref = actionType === SolutionActionType.app ? androidLink : playStoreLink;
  return (
    <Box sx={containerStyle}>
      <p style={titleStyle}>{title}</p>
      {iosLinkHref && (
        <a href={iosLinkHref} onClick={() => onClick("iOS")} target="_blank" rel="noreferrer">
          <AppStoreLogo iconsStyle={iconsStyle} />
        </a>
      )}
      {androidLinkHref && (
        <a
          href={androidLinkHref}
          onClick={() => onClick("Android")}
          target="_blank"
          rel="noreferrer"
        >
          <PlayStoreLogo iconsStyle={iconsStyle} />
        </a>
      )}
    </Box>
  );
};

export default IntermediateOpenAppNode;
