import MenuIcon from "@mui/icons-material/Menu";
import { SxProps, Theme } from "@mui/material";
import { MouseEvent, ReactNode, forwardRef } from "react";
import { Text } from "../../data-display";
import { Button, IconButton } from "../../input-selection";
import { Box } from "../../layout";
import { VerticalMenu, VerticalMenuProps } from "./VerticalMenu";

type DropdownMenuProps = {
  icon?: ReactNode;
  id: string;
  menuTitle: string;
  onOpenMenu(event: MouseEvent<HTMLElement>): void;
  sx?: SxProps<Theme>;
} & VerticalMenuProps;

export const DropdownMenu = forwardRef<HTMLDivElement, DropdownMenuProps>(
  (
    {
      anchor,
      anchorOrigin,
      icon,
      id,
      items = [],
      menuTitle,
      onClose,
      onOpenMenu,
      pathname,
      sx,
      signOut,
      transformOrigin,
      ...props
    },
    ref
  ) => {
    if (items.length === 0 && !signOut) {
      return null;
    }

    return (
      <Box id={id} ref={ref} sx={sx} {...props}>
        {icon ? (
          <Button
            aria-label={menuTitle}
            aria-controls={id}
            aria-haspopup="true"
            color="secondary"
            startIcon={icon}
            onClick={onOpenMenu}
            size="lg"
            sx={{ maxWidth: "15rem" }}
          >
            <Text
              component="strong"
              sx={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                display: "block",
                fontSize: "inherit",
              }}
            >
              {menuTitle ?? undefined}
            </Text>
          </Button>
        ) : (
          <IconButton
            aria-label={menuTitle}
            aria-controls={id}
            aria-haspopup="true"
            color="secondary"
            onClick={onOpenMenu}
            size="lg"
            sx={{ marginRight: 2 }}
          >
            <MenuIcon />
          </IconButton>
        )}

        <VerticalMenu
          anchorOrigin={anchorOrigin}
          anchor={anchor}
          id={`${id}__menu`}
          items={items}
          onClose={onClose}
          pathname={pathname}
          signOut={signOut}
          transformOrigin={transformOrigin}
        />
      </Box>
    );
  }
);
