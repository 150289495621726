import { FC } from "react";

export type Props = {
  id: string;
  name: string;
};

const DropdownItem: FC<Props> = ({ id, name }) => (
  <option
    value={id}
    className="ich-dropdown-select-option"
    data-testid="ich-dropdown-select-option"
  >
    {name}
  </option>
);
export default DropdownItem;
