import { forwardRef } from "react";
import {
  DialogContent as MUIDialogContent,
  DialogContentProps as MUIDialogContentProps,
} from "@mui/material";

export type DialogContentProps = MUIDialogContentProps;

export const DialogContent = forwardRef<HTMLDivElement, DialogContentProps>((props, ref) => {
  return <MUIDialogContent ref={ref} {...props} />;
});
