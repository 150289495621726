import {
  Menu as MUIMenu,
  MenuItem as MUIMenuItem,
  PopoverOrigin,
  SxProps,
  Theme,
} from "@mui/material";
import { forwardRef } from "react";
import { Text } from "../../data-display";
import { Link } from "../Link";
import { AppBarAction, AppBarLink } from "./AppBar";
import { getKey } from "./getKey";
import { getLinkColor } from "./getLinkColor";
import { getNextLink } from "./getNextLink";

export type VerticalMenuProps = {
  id: string;
  anchor: HTMLElement | null;
  onClose: () => void;
  items?: AppBarLink[];
  signOut?: AppBarAction;
  anchorOrigin: PopoverOrigin;
  transformOrigin: PopoverOrigin;
  sx?: SxProps<Theme>;
  pathname?: string;
};

export const VerticalMenu = forwardRef<HTMLDivElement, VerticalMenuProps>(
  (
    {
      id,
      anchor,
      onClose,
      items = [],
      signOut,
      pathname,
      sx,
      anchorOrigin,
      transformOrigin,
      ...props
    },
    ref
  ) => {
    if (items.length === 0 && !signOut) {
      return null;
    }

    return (
      <MUIMenu
        ref={ref}
        id={id}
        anchorEl={anchor}
        anchorOrigin={anchorOrigin}
        keepMounted
        transformOrigin={transformOrigin}
        open={Boolean(anchor)}
        onClose={onClose}
        sx={sx}
        {...props}
      >
        {items.map(({ label, link, nextLink }) => (
          <MUIMenuItem key={getKey(link)} onClick={onClose}>
            <Link
              color={getLinkColor(link, pathname)}
              href={link}
              nextLink={getNextLink(link, nextLink)}
              size="md"
              textAlign="center"
            >
              {label}
            </Link>
          </MUIMenuItem>
        ))}

        {signOut && (
          <MUIMenuItem
            onClick={() => {
              signOut.action();
            }}
          >
            <Text color="secondary" textAlign="center" size="md" sx={{ cursor: "pointer" }}>
              {signOut.label}
            </Text>
          </MUIMenuItem>
        )}
      </MUIMenu>
    );
  }
);
