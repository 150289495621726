import React from "react";
import { Box } from "@mui/material";

const activeCss = {
  fontWeight: "bold",
};

const linkCss = {
  display: "inline",
  alignItems: "center",
  cursor: "pointer",
};

export type Props = {
  isActive: boolean;
  onClick: () => void;
  title: string;
  name: string;
};

const MenuLink: React.FC<Props> = ({ isActive, onClick, title, name }) => {
  let displayTitle = name;
  if (title.length > 0) displayTitle = title;
  return (
    <Box onClick={onClick} sx={[linkCss, isActive && activeCss]}>
      {displayTitle}
    </Box>
  );
};

export default MenuLink;
