import { viewerTheme } from "../client/theme";
import { AppProps } from "next/app";
import { CssBaseline, GlobalStyles } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Head from "next/head";
import DataLoader from "../client/DataLoader";
import { Spinner, globalStyle } from "../client";
import useRecoverParamsFromUrl from "../client/hooks/useRecoverParamsFromUrl";

const queryClient = new QueryClient();

function CustomApp({ Component, pageProps, router }: AppProps) {
  const { query, isReady } = router;
  const routerIsReady = isReady || Object.keys(query).length !== 0;

  useRecoverParamsFromUrl(router);

  return (
    <>
      <Head>
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
        <meta httpEquiv="date" content="" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <meta
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
          name="viewport"
        />
      </Head>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={viewerTheme}>
          <CssBaseline />
          <GlobalStyles styles={globalStyle} />
          {routerIsReady ? (
            <DataLoader router={router} Component={Component} {...pageProps} />
          ) : (
            <div className="centeredSpinner">
              <Spinner />
            </div>
          )}
          {process.env.NODE_ENV !== "production" && <ReactQueryDevtools initialIsOpen={false} />}
        </ThemeProvider>
      </QueryClientProvider>
    </>
  );
}

export default CustomApp;
