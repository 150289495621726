import { FC } from "react";
import { GenericSolutionCard } from "../ui";
import { ActionPostalMail } from "../../types";

const title = "Veuillez nous écrire à l'adresse suivante :";

const PostalAddressNode: FC<ActionPostalMail> = ({
  address1 = "",
  address2 = "",
  postalCode = "",
  city = "",
  country = "",
}) => {
  return (
    <GenericSolutionCard
      className="ich3-action-postalMail"
      title={title}
      type="pencil"
      paragraphs={[address1, address2, `${postalCode} ${city}`, country]}
    />
  );
};

export default PostalAddressNode;
