import { theme } from "@do/ui-do";
import { createTheme } from "@mui/material/styles";

// Create a theme instance.
export const viewerTheme = createTheme({
  ...theme,
  typography: {
    h6: {
      fontSize: "1.25rem",
      "@media (max-width: 525px)": {
        fontSize: "1rem",
      },
    },
  },
});

export type ThemeType = typeof theme;
