import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog as MUIDialog,
  DialogActions as MUIDialogActions,
  DialogActionsProps as MUIDialogActionsProps,
  DialogContent as MUIDialogContent,
  DialogContentProps as MUIDialogContentProps,
  DialogProps as MUIDialogProps,
  DialogTitle as MUIDialogTitle,
  DialogTitleProps as MUIDialogTitleProps,
} from "@mui/material";
import { ReactNode, forwardRef } from "react";
import { ButtonColor, ButtonSize, ButtonVariant } from "../../../types";
import { Button } from "../../input-selection";

export type CustomDialogAction = {
  color?: ButtonColor;
  disabled?: boolean;
  formId?: string;
  indexFromRight: number;
  label: string;
  loading?: boolean;
  onClick?: () => void | Promise<void>;
  size?: ButtonSize;
  type?: "submit" | "reset" | "button";
  variant?: ButtonVariant;
};

export type DialogProps = Omit<MUIDialogProps, "open"> & {
  actions?: CustomDialogAction[];
  actionsProps?: MUIDialogActionsProps;
  closeButton?: boolean;
  children?: ReactNode;
  contentProps?: MUIDialogContentProps;
  onClose: () => void;
  open?: boolean;
  title?: string;
  titleProps?: MUIDialogTitleProps;
};

export type DialogButtonProps = Omit<CustomDialogAction, "indexFromRight" | "label"> & {
  form?: string;
  key?: string;
};

export const Dialog = forwardRef<HTMLDivElement, DialogProps>(
  (
    {
      actions = [],
      actionsProps,
      children,
      closeButton = true,
      contentProps,
      onClose,
      open = false,
      title,
      titleProps,
      ...props
    },
    ref
  ) => {
    const dialogTitleId = "dialog-title";

    const CustomTitle = () => {
      if (!title) return null;

      return (
        <MUIDialogTitle id={dialogTitleId} {...titleProps}>
          {title}
        </MUIDialogTitle>
      );
    };

    const CustomActions = () => {
      if (actions.length === 0) return null;

      const ActionsButtons = () => {
        return (
          <MUIDialogActions {...actionsProps}>
            {actions
              .sort((a, b) => b.indexFromRight - a.indexFromRight)
              .map((action) => {
                const buttonProps: DialogButtonProps = {
                  color: action.color ?? "primary",
                  disabled: action.disabled,
                  key: action.label,
                  loading: action.loading ?? false,
                  size: action.size ?? "md",
                  variant: action.variant ?? "contained",
                };

                switch (action.type) {
                  case "submit":
                    buttonProps.form = action.formId;
                    buttonProps.type = "submit";
                    break;
                  case "reset":
                    buttonProps.form = action.formId;
                    buttonProps.type = "reset";
                    break;
                  default:
                    buttonProps.onClick = action.onClick;
                    break;
                }

                return <Button {...buttonProps}>{action.label}</Button>;
              })}
          </MUIDialogActions>
        );
      };

      return <ActionsButtons />;
    };

    return (
      <MUIDialog aria-labelledby={dialogTitleId} ref={ref} {...props} onClose={onClose} open={open}>
        <CustomTitle />

        {closeButton && (
          <CloseIcon
            aria-label="close"
            onClick={onClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
              cursor: "pointer",
              position: "absolute",
              right: 16,
              top: 20,
            }}
          />
        )}

        <MUIDialogContent {...contentProps}>{children}</MUIDialogContent>

        <CustomActions />
      </MUIDialog>
    );
  }
);
