import { effect, signal, Signal } from "@preact/signals-react";
import { getInSessionStorage, setInSessionStorage } from "../helpers";

export type ScenarioParams = {
  branch?: string;
  integrationType?: string;
  pageKey?: string;
  pinCode?: string;
  scenarioId?: string;
  shortCode?: string;
  solutionId?: string;
};

const SESSION_STORAGE_KEY = "scenarioParams";

function getScenarioParamsFromSessionStorage(key: string): ScenarioParams {
  const defaultValue: ScenarioParams = {};

  try {
    const storedScenarioParams = getInSessionStorage(key);

    if (!storedScenarioParams) {
      return defaultValue;
    }

    return JSON.parse(storedScenarioParams);
  } catch {
    return defaultValue;
  }
}

export const scenarioParams: Signal<ScenarioParams> = signal(
  getScenarioParamsFromSessionStorage(SESSION_STORAGE_KEY)
);

export function mergeScenarioParams(params: ScenarioParams) {
  // We need to filter nullish values because they are overriding stored ones
  const onlyNotNullishValues = Object.fromEntries(
    Object.entries(params).filter(([_, value]) => value)
  );
  const currentScenarioParams = scenarioParams.value;

  scenarioParams.value = {
    ...currentScenarioParams,
    ...onlyNotNullishValues,
  };
}

effect(() => setInSessionStorage(SESSION_STORAGE_KEY, JSON.stringify(scenarioParams)));
