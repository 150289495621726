import { NextRouter } from "next/router";
import { NextPage } from "next/types";
import { useMemo } from "react";
import { ScenarioAnalyticsDocumentActionType } from "../../types";
import { FilteredResultType } from "../DataLoader";
import { postScenarioDetails, renderSolution } from "../helpers";
import { DelayDisplay } from "../molecules";
import { PageContainer } from "../templates";

type SolutionPageProps = {
  shortCode: string;
  branch: string;
  router: NextRouter;
  filteredResult: FilteredResultType;
  mongoSolutionId: string;
};

const SolutionPage: NextPage<SolutionPageProps> = ({
  shortCode,
  filteredResult,
  mongoSolutionId,
  router,
}) => {
  const previewMode = useMemo(() => {
    return router.pathname.includes("/p/");
  }, [router]);
  return (
    <PageContainer>
      <DelayDisplay delay={filteredResult.scenarioData.delay}>
        {renderSolution({
          previewMode,
          solutionId: mongoSolutionId,
          scenarioData: filteredResult.scenarioData,
          onStoreOrAppClick: async () => {
            await postScenarioDetails({
              type: ScenarioAnalyticsDocumentActionType.Solution,
              shortCode,
              solutionType: filteredResult.scenarioData.type,
              solutionId: mongoSolutionId,
            });
          },
        })}
      </DelayDisplay>
    </PageContainer>
  );
};

export default SolutionPage;
