import { DEFAULT_CDN_URL, Locale } from "@do/shared-types";
import { Box, BoxProps } from "@mui/material";
import { memo } from "react";
import { flagNames } from "../constants";
import { localeToFlag } from "../utils";

type FlagProps = BoxProps & {
  immediatelyVisible?: boolean;
  locale: Locale;
  squared?: boolean;
};

export const Flag = memo<FlagProps>(
  ({ immediatelyVisible, locale, squared, sx = {}, ...boxProps }) => {
    const flagName = localeToFlag(locale) as keyof typeof flagNames;
    return (
      <Box
        sx={{
          aspectRatio: squared ? 1 : 4 / 3,
          display: "inline-block",
          height: "1.4em",
          "&>img": { objectFit: "contain" },
          ...sx,
        }}
        {...boxProps}
      >
        <img
          alt={flagNames[flagName]}
          loading={immediatelyVisible ? "eager" : "lazy"}
          src={`${DEFAULT_CDN_URL}/img/dialonce/flags/${squared ? "1x1" : "4x3"}/${flagName}.svg`}
        />
      </Box>
    );
  }
);
Flag.displayName = "Flag";
