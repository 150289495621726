import { forwardRef } from "react";
import { Link as MUILink, LinkProps as MUILinkProps } from "@mui/material";
import NextLink, { LinkProps as NextLinkProps } from "next/link";
import type { LinkColor, TextSize, TextVariant } from "../../types";
import { variants } from "../../constants";

export type LinkProps = Omit<MUILinkProps, "href" | "classes"> &
  Pick<NextLinkProps, "href" | "as" | "prefetch"> & {
    nextLink?: boolean;
    variant?: TextVariant;
    size?: TextSize;
    color?: LinkColor;
  };

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  (
    {
      href,
      as,
      prefetch,
      size = "md",
      variant = "inherit",
      underline = "none",
      color = "primary",
      sx,
      onClick,
      nextLink = true,
      ...props
    },
    ref
  ) => {
    if (typeof href !== "string" && !nextLink && !onClick) {
      throw new Error("href must be a string when nextLink is false");
    }

    const propsForLink: MUILinkProps = {
      variant: size,
      underline,
      color: color,
      sx: {
        ...variants[variant],
        ...sx,
      },
      ...props,
    };

    if (!nextLink && onClick) {
      propsForLink.component = "button";
      propsForLink.onClick = onClick;
    }

    if (!nextLink && href) {
      propsForLink.href = href as string;
    }

    return nextLink ? (
      <NextLink href={href} as={as} prefetch={prefetch} passHref legacyBehavior>
        <MUILink ref={ref} {...propsForLink} />
      </NextLink>
    ) : (
      <MUILink ref={ref} href={href as string} {...propsForLink} />
    );
  }
);
