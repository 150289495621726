import { Divider as MUIDivider, DividerProps as MUIDividerProps } from "@mui/material";
import { forwardRef } from "react";

type DividerProps = MUIDividerProps;

export const Divider = forwardRef<HTMLHRElement, DividerProps>((props, ref) => (
  <MUIDivider ref={ref} {...props} />
));

export default Divider;
