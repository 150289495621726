import { PushWrapperFn } from "../../../types/scenarioAnalytics";

type HandleInternalFaqActionArgs = {
  pageKey?: string;
  shortCode: string | undefined;
  scenarioId: string | undefined;
  toPageKeyForAnalytics?: string;
  fromIntentId?: string;
  routerPush: PushWrapperFn;
};

export const handleInternalFaqAction = async ({
  pageKey,
  shortCode,
  scenarioId,
  toPageKeyForAnalytics,
  fromIntentId,
  routerPush,
}: HandleInternalFaqActionArgs) => {
  if (!pageKey) return false;
  try {
    const url = shortCode ? `/c/${shortCode}/${pageKey}` : `/p/${scenarioId}/${pageKey}`;
    const toPageKey = toPageKeyForAnalytics || pageKey;
    await routerPush(url, { toPageKey, fromIntentId });
    return true;
  } catch (_) {
    return false;
  }
};
