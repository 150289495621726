import {
  Step as MUIStep,
  StepContent as MUIStepContent,
  StepContentProps as MUIStepContentProps,
  StepLabel as MUIStepLabel,
  StepLabelProps as MUIStepLabelProps,
  Stepper as MUIStepper,
  StepperProps as MUIStepperProps,
  StepProps as MUIStepProps,
  StepConnector,
  stepConnectorClasses,
  StepIconProps,
  styled,
  SxProps,
  Theme,
} from "@mui/material";
import type { FC, ReactNode } from "react";
import { forwardRef } from "react";
import { doTheme } from "../../doTheme";
import { classNames, getXEquidistantSetOfColorsBetweenTwoColorCodes } from "../../helpers";
import { SrOnly } from "../accessibility";
import { Box } from "../layout";

export type StepType = Omit<MUIStepProps, "content"> & {
  label: MUIStepLabelProps & {
    children?: ReactNode;
    ariaLabel?: string;
  };
  content?: MUIStepContentProps & {
    children?: ReactNode;
  };
  disabled?: boolean;
  selected?: boolean;
};

type StepperProps = Omit<MUIStepperProps, "activeStep"> & {
  changeOnClick?: boolean;
  gradientColors?: string[];
  setStepIndex?: (index: number) => void;
  stepIndex: number;
  steps: StepType[];
  theme?: "default" | "builder";
};

const BuilderConnector = styled(StepConnector)(() => ({
  flex: 0,
  [`& .${stepConnectorClasses.line}`]: {
    borderTopWidth: 0,
  },
}));

const DefaultConnector = styled(StepConnector)(({ theme }) => ({
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.grey[300],
  },
  [`& .${stepConnectorClasses.lineHorizontal}`]: {
    borderTopWidth: 2,
  },
  [`& .${stepConnectorClasses.lineVertical}`]: {
    borderLeftWidth: 2,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
}));

const BuilderStepComponent: FC<
  StepIconProps & {
    selected?: boolean;
    sx: SxProps<Theme> & {
      backgroundColor: string;
    };
  }
> = ({ active, completed, error, selected, sx }) => {
  const isActive = active || completed || selected;

  return (
    <Box
      rounded="full"
      sx={{
        display: "flex",
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: 11,
        background: isActive ? sx?.backgroundColor : undefined,
        backgroundColor: "grey.200",
        color: error ? "error.main" : "black",
      }}
    />
  );
};

export const Stepper = forwardRef<HTMLDivElement, StepperProps>(
  (
    {
      changeOnClick = false,
      connector,
      gradientColors,
      setStepIndex,
      orientation = "horizontal",
      stepIndex,
      steps,
      theme = "default",
      ...props
    },
    ref
  ) => {
    const linearColors = getXEquidistantSetOfColorsBetweenTwoColorCodes(
      gradientColors?.[0] ?? doTheme.palette.tertiaryBlue?.main,
      gradientColors?.[1] ?? doTheme.palette.primary?.main,
      steps.length
    );

    return (
      <MUIStepper
        ref={ref}
        activeStep={stepIndex}
        orientation={orientation}
        connector={
          theme === "builder" ? <BuilderConnector /> : connector ? connector : <DefaultConnector />
        }
        {...props}
      >
        {steps.map((s, index) => {
          const {
            label,
            content,
            disabled,
            selected,
            sx: stepSx,
            className: stepClassName,
            ...stepProps
          } = s;
          const {
            children: labelChildren,
            ariaLabel,
            sx: labelSx,
            StepIconComponent,
            className: labelClassName,
            ...labelProps
          } = label;
          const { sx: contentSx, ...contentProps } = content ?? {};
          const linearColor = linearColors[index];

          return (
            <MUIStep
              key={index}
              sx={{
                cursor: disabled ? "not-allowed" : changeOnClick ? "pointer" : "default",
                "&.StepBuilder": {
                  flexGrow: 1,
                  width: 100 / steps.length + "%",
                },
                opacity: disabled ? 0.75 : 1,
                ...stepSx,
              }}
              disabled={disabled}
              onClick={() => {
                changeOnClick && !disabled && setStepIndex && setStepIndex(index);
              }}
              className={classNames(theme === "builder" && "StepBuilder", stepClassName)}
              {...stepProps}
            >
              <MUIStepLabel
                className={classNames(
                  orientation === "horizontal" ? "StepLabelHorizontal" : "StepLabelVertical",
                  theme === "builder" ? "StepLabelBuilder" : "StepLabelDefault",
                  Boolean(labelChildren) && "StepLabelChildren",
                  labelClassName
                )}
                sx={{
                  display: "flex",

                  "& .MuiStepLabel-labelContainer": {
                    "& .Mui-active": {
                      color: "common.black",
                      fontWeight: "bold",
                    },
                    "& .Mui-disabled": { color: !selected ? "grey.400" : "common.black" },
                    "& .Mui-error": { color: "error.main" },
                  },

                  "&.StepLabelHorizontal": {
                    "& .MuiStepLabel-iconContainer": { paddingRight: 0 },
                    "& .MuiStepLabel-labelContainer": { textAlign: "center" },
                  },

                  "&.StepLabelBuilder": {
                    flexDirection: "column-reverse",
                    "&.StepLabelChildren .MuiStepLabel-labelContainer": {
                      paddingBottom: "12px",
                    },
                    "& .MuiStepLabel-iconContainer": { width: "100%" },
                  },

                  "&.StepLabelDefault": {
                    "&.StepLabelHorizontal": {
                      flexDirection: "column",
                    },
                    "&.StepLabelHorizontal.StepLabelChildren .MuiStepLabel-labelContainer": {
                      paddingTop: "8px",
                    },
                    "& .Mui-disabled .MuiSvgIcon-root": { color: "grey.300" },
                    "& .Mui-error .MuiSvgIcon-root": { color: "error.main" },
                  },
                  ...labelSx,
                }}
                StepIconComponent={
                  theme === "builder"
                    ? (BuilderStepComponent as FC<StepIconProps>)
                    : StepIconComponent
                }
                StepIconProps={
                  {
                    sx: {
                      backgroundColor: `linear-gradient(90deg, ${linearColor[0]} 0%, ${linearColor[1]} 100%)`,
                    },
                    selected,
                    icon: index + 1,
                  } as StepIconProps
                }
                {...labelProps}
              >
                {labelChildren ?? <SrOnly>{ariaLabel}</SrOnly>}
              </MUIStepLabel>
              {content && orientation === "vertical" && (
                <MUIStepContent
                  sx={{
                    borderColor: "grey.300",
                    borderWidth: 2,
                    ...contentSx,
                  }}
                  {...contentProps}
                />
              )}
            </MUIStep>
          );
        })}
      </MUIStepper>
    );
  }
);
