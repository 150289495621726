import { FC } from "react";

export type Props = {
  view: string;
  onClick: () => void;
};

const IntentNode: FC<Props> = ({ view, onClick }) => (
  <button
    onClick={onClick}
    style={{ width: "100%" }}
    className="ich-intent"
    dangerouslySetInnerHTML={{ __html: view }}
  />
);

export default IntentNode;
