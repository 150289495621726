import { IntentType, IntentToSingleSolutionType } from "./intent";
import SolutionType from "./solution";

export type ActionApp = {
  iosLink?: string;
  androidLink?: string;
  introductionText?: string;
  os?: ActionTypeAppOS;
};

export enum OpenTypeContextualizedRedirectionEnum {
  sameWindow = "sameWindow",
  newWindow = "newWindow",
}

export type ActionContextualizedRedirection = {
  openType?: OpenTypeContextualizedRedirectionEnum;
  location?: string;
};

export enum ActionTypeAppOS {
  iOS = "iOS",
  Android = "Android",
  other = "other",
}

export type ActionUrl = {
  url?: string;
};

export type ActionEmail = {
  to?: string;
  subject?: string;
  body?: string;
  cc?: string[];
  bcc?: string[];
};

export type ActionInternalFaq = {
  pageKey?: string;
};

export declare enum IAdvizeDisplayByDefault {
  OnlineView = 1,
  OfflineView = 2,
  None = 3,
}

export type ActionLiveChat = {
  url?: string;
  sid?: string;
  onlineId?: string;
  offlineId?: string;
  onlineContent?: string;
  offlineContent?: string;
  displayByDefault?: IAdvizeDisplayByDefault;
  code?: string;
};

export type ActionPostalMail = {
  address1?: string;
  address2?: string;
  postalCode?: string;
  city?: string;
  country?: string;
};

export type ActionStore = {
  playStoreLink?: string;
  appStoreLink?: string;
  os?: ActionTypeAppOS;
};

export type ActionIvr = {
  phoneNumber?: string;
};

export type ActionIframe = {
  url?: string;
  isFastform?: boolean;
};

export type Action = ActionApp &
  ActionUrl &
  ActionContextualizedRedirection &
  ActionEmail &
  ActionInternalFaq &
  ActionLiveChat &
  ActionPostalMail &
  ActionStore &
  ActionIvr &
  ActionIframe;

export type IntentSolutionType = IntentType | IntentToSingleSolutionType | SolutionType;
