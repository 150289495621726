import {
  UserAgentBrowser,
  UserAgentEngine,
  UserAgentOs,
  UserAgentPlatform,
  UserAgentVendor,
} from "./types";

export const USER_AGENT_BROWSERS = {
  "Amazon Silk": UserAgentBrowser.AmazonSilk,
  "Android Browser": UserAgentBrowser.AndroidBrowser,
  Bada: UserAgentBrowser.Bada,
  BlackBerry: UserAgentBrowser.BlackBerry,
  Chrome: UserAgentBrowser.Chrome,
  Chromium: UserAgentBrowser.Chromium,
  Electron: UserAgentBrowser.Electron,
  Epiphany: UserAgentBrowser.Epiphany,
  Firefox: UserAgentBrowser.Firefox,
  Focus: UserAgentBrowser.Focus,
  Generic: UserAgentBrowser.Generic,
  "Google Search": UserAgentBrowser.GoogleSearch,
  Googlebot: UserAgentBrowser.GoogleBot,
  "Internet Explorer": UserAgentBrowser.InternetExplorer,
  "K-Meleon": UserAgentBrowser.KMeleon,
  Maxthon: UserAgentBrowser.Maxthon,
  "Microsoft Edge": UserAgentBrowser.MicrosoftEdge,
  "MZ Browser": UserAgentBrowser.MzBrowser,
  "NAVER Whale Browser": UserAgentBrowser.NaverWhaleBrowser,
  Opera: UserAgentBrowser.Opera,
  "Opera Coast": UserAgentBrowser.OperaCoast,
  PhantomJS: UserAgentBrowser.PhantomJs,
  Puffin: UserAgentBrowser.Puffin,
  QupZilla: UserAgentBrowser.QupZilla,
  QQ: UserAgentBrowser.Qq,
  QQLite: UserAgentBrowser.QqLite,
  Safari: UserAgentBrowser.Safari,
  Sailfish: UserAgentBrowser.Sailfish,
  "Samsung Internet for Android": UserAgentBrowser.SamsungInternetForAndroid,
  SeaMonkey: UserAgentBrowser.SeaMonkey,
  Sleipnir: UserAgentBrowser.Sleipnir,
  Swing: UserAgentBrowser.Swing,
  Tizen: UserAgentBrowser.Tizen,
  "UC Browser": UserAgentBrowser.UcBrowser,
  Vivaldi: UserAgentBrowser.Vivaldi,
  "WebOS Browser": UserAgentBrowser.WebOsBrowser,
  WeChat: UserAgentBrowser.WeChat,
  "Yandex Browser": UserAgentBrowser.YandexBrowser,
  Roku: UserAgentBrowser.Roku,
};

export const USER_AGENT_PLATFORNS = {
  tablet: UserAgentPlatform.Tablet,
  mobile: UserAgentPlatform.Mobile,
  desktop: UserAgentPlatform.Desktop,
  tv: UserAgentPlatform.Tv,
};

export const USER_AGENT_OSS = {
  "Windows Phone": UserAgentOs.WindowsPhone,
  Windows: UserAgentOs.Windows,
  macOS: UserAgentOs.MacOs,
  iOS: UserAgentOs.Ios,
  Android: UserAgentOs.Android,
  WebOS: UserAgentOs.WebOS,
  BlackBerry: UserAgentOs.BlackBerry,
  Bada: UserAgentOs.Bada,
  Tizen: UserAgentOs.Tizen,
  Linux: UserAgentOs.Linux,
  "Chrome OS": UserAgentOs.ChromeOs,
  "PlayStation 4": UserAgentOs.PlayStation4,
  Roku: UserAgentOs.Roku,
};

export const USER_AGENT_ENGINES = {
  EdgeHTML: UserAgentEngine.EdgeHtml,
  Blink: UserAgentEngine.Blink,
  Trident: UserAgentEngine.Trident,
  Presto: UserAgentEngine.Presto,
  Gecko: UserAgentEngine.Gecko,
  WebKit: UserAgentEngine.WebKit,
};

export const USER_AGENT_VENDORS = {
  Apple: UserAgentVendor.Apple,
  BlackBerry: UserAgentVendor.BlackBerry,
  Microsoft: UserAgentVendor.Microsoft,
  Google: UserAgentVendor.Google,
  Huawei: UserAgentVendor.Huawei,
  Nexus: UserAgentVendor.Nexus,
  Amazon: UserAgentVendor.Amazon,
};
