import { forwardRef } from "react";
import {
  DialogTitle as MUIDialogTitle,
  DialogTitleProps as MUIDialogTitleProps,
} from "@mui/material";

export type DialogTitleProps = MUIDialogTitleProps;

export const DialogTitle = forwardRef<HTMLElement, DialogTitleProps>((props, ref) => {
  return <MUIDialogTitle ref={ref} {...props} />;
});
