import { GlobalStylesProps } from "@mui/material";
import { size } from "polished";

export const globalStyle: GlobalStylesProps["styles"] = {
  fontFamily:
    "system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji",
  "html, body, #__next, .centeredSpinner": size("100%"),
  iframe: {
    ...size("calc(100dvh - 180px)", "100%"),
    margin: "0 auto",
    display: "block",
    border: 0,
  },
  ".centeredSpinner": {
    display: "flex",
    marginTop: "50px",
  },
};
