import { SxProps } from "@mui/system";
import { ChangeEvent, FocusEvent, forwardRef, useCallback, useMemo, useState } from "react";
import { defaultLanguage } from "../../constants/language";
import { Language } from "../../types/language";
import TextField from "./TextField";

export type MultilineUrlsProps = {
  label?: string;
  ariaLabel?: string;
  disabled?: boolean;
  domains?: string[];
  error?: boolean;
  fullWidth?: boolean;
  helperText?: string;
  language?: Language;
  maxUrls?: number;
  maxRows?: number;
  minRows?: number;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  setValue: (value: string[]) => void;
  value: string[];
  sx?: SxProps;
};

export const MultilineUrls = forwardRef<HTMLDivElement, MultilineUrlsProps>(
  (
    {
      label,
      ariaLabel,
      disabled,
      domains,
      error: parentError,
      fullWidth,
      helperText,
      language = defaultLanguage,
      maxUrls,
      maxRows,
      minRows = 3,
      readOnly,
      placeholder,
      required = false,
      setValue,
      value,
      sx,
    },
    ref
  ) => {
    const [localError, setLocalError] = useState(false);
    const [localErrorMessage, setLocalErrorMessage] = useState("");
    const [localInfoMessage, setLocalInfoMessage] = useState("");
    const arrayToStringValue = useMemo(
      () => value && value.join(",").replace(/[,; ]/g, "\n"),
      [value]
    );
    const urlsList = useMemo(() => (domains ? domains.join(" or ") : ""), [domains]);

    const traductions = useMemo(
      () => ({
        [Language.en]: {
          helperText: {
            default: helperText ?? `Tips: start a new line by pressing 'enter'`,
            error: {
              acceptedDomains: urlsList ? `Accepted domains: ${urlsList}` : `All domains accepted`,
              maxLimitation: `Limitation of ${maxUrls} URLs maximum reached`,
              urlValidity: 'Each URL must start by "https://"',
            },
          },
          placeholder: {
            default:
              placeholder ?? (maxUrls ? `List your URLs (${maxUrls} maximum)` : "List your URLs"),
          },
          ariaLabel: "List of URLs",
        },
        [Language.fr]: {
          helperText: {
            default:
              helperText ?? `Astuce : appuyer sur la touche 'entrée' pour retourner à la ligne`,
            error: {
              acceptedDomains: urlsList
                ? `Noms de domaine acceptés : ${urlsList}`
                : `Tous les noms de domains sont acceptés`,
              maxLimitation: `Limite de ${maxUrls} URLs maximum atteinte`,
              urlValidity: 'Chaque URL doit commencer par "https://"',
            },
          },
          placeholder: {
            default:
              placeholder ?? (maxUrls ? `Listez vos URLs (${maxUrls} maximum)` : `Listez vos URLs`),
          },
          ariaLabel: "Liste d'URLs",
        },
      }),
      [helperText, maxUrls, placeholder, urlsList]
    );

    const checkIfUrlIsValid = (url: string) => {
      try {
        const newUrl = new URL(url);
        // new URL() seems consider URL like https:dialonce.com as valid URL.
        // So we also test that `url` properly starts with `https://`
        const isValidUrl = newUrl.protocol === "https:" && url.startsWith("https://");

        if (!isValidUrl) {
          setLocalErrorMessage(traductions[language].helperText.error.urlValidity);
        }

        return isValidUrl;
      } catch (err) {
        setLocalErrorMessage(traductions[language].helperText.error.urlValidity);
        return false;
      }
    };

    const checkIfMaxRowsIsExceeded = (array: string[]): boolean => {
      if (!maxRows) return false;

      const isExceeded = array.length > maxRows;

      if (isExceeded) {
        setLocalErrorMessage(traductions[language].helperText.error.maxLimitation);
      }

      return isExceeded;
    };

    const applyMaxUrls = (array: string[]) => {
      if (!maxUrls) return array;

      const isReached = array.length >= maxUrls;

      if (isReached) {
        setLocalInfoMessage(traductions[language].helperText.error.maxLimitation);

        return array.splice(0, maxUrls);
      }

      return array;
    };

    const checkIfUrlIsMatchingHostname = (url: string) => {
      if (!domains || domains.length === 0) return true;

      let isHostnameMatching = false;

      domains.forEach((domain) => {
        if (!isHostnameMatching) {
          const { hostname: urlHostname } = new URL(url);
          const { hostname: acceptedHostname } = new URL(domain);

          isHostnameMatching = urlHostname === acceptedHostname;
        }
      });

      if (!isHostnameMatching) {
        setLocalErrorMessage(traductions[language].helperText.error.acceptedDomains);
      }

      return isHostnameMatching;
    };

    type CleanLastCharacterResponse = {
      cleaned: boolean;
      value: string;
    };

    const cleanLastCharacter = (targetValue: string): CleanLastCharacterResponse => {
      let cleaned = false;
      const isLastCharacterASeparator = [" ", ";", ","].includes(
        targetValue[targetValue.length - 1]
      );

      if (isLastCharacterASeparator) {
        cleaned = true;
        targetValue = targetValue.substring(0, targetValue.length - 1);

        // trimming is necessary as targetValue could have more than "one space"
        targetValue = targetValue.trim();
      }
      return { cleaned, value: targetValue };
    };

    const removeUnnecessarySpace = (values: string[]) => {
      const filtered = values.filter((value) => value !== " " && value !== "");

      return filtered;
    };

    const deduplicateArray = (values: string[]) => {
      // to remove duplicates (see details https://stackoverflow.com/a/9229784/4764246)
      const s = new Set(values);
      const it = s.values();
      const array = Array.from(it) as string[];

      return array;
    };

    const getPlaceholder = useCallback(() => {
      return required
        ? traductions[language].placeholder.default + " *"
        : traductions[language].placeholder.default;
    }, [language, required, traductions]);

    const checkLocalError = (values: string[], checkLastItem = false): boolean => {
      // checkLastItem is used to prevent showing error while user typing a new line (generally at the end)
      const valuesToCheck = [...values];
      if (!checkLastItem) {
        valuesToCheck.pop();
      }

      if (valuesToCheck.length === 0 || valuesToCheck[valuesToCheck.length - 1] === "")
        return false;

      let hasError = valuesToCheck.some((value) => !checkIfUrlIsValid(value));
      if (!hasError) {
        hasError = valuesToCheck.some((value) => !checkIfUrlIsMatchingHostname(value));
      }
      if (!hasError) {
        hasError = checkIfMaxRowsIsExceeded(valuesToCheck);
      }

      return hasError;
    };

    const getError = useCallback(() => {
      return parentError ? parentError : localError;
    }, [localError, parentError]);

    const getHelperText = useCallback(() => {
      if (localError && localErrorMessage) {
        return localErrorMessage;
      }

      return localInfoMessage ? localInfoMessage : traductions[language].helperText.default;
    }, [localError, localErrorMessage, language, localInfoMessage, traductions]);

    const convertEventTargetValueToArray = (targetValue: string): string[] => {
      const { cleaned, value: cleanedTargetValue } = cleanLastCharacter(targetValue);

      let splitTargetValue = cleanedTargetValue.split("\n");

      if (cleaned) {
        splitTargetValue = [...splitTargetValue, ""];
      }

      return splitTargetValue;
    };

    const onBlur = (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
      const targetValue = e.target.value;
      const convertedToArray = convertEventTargetValueToArray(targetValue);
      const deduplicated = deduplicateArray(convertedToArray);
      const cleaned = removeUnnecessarySpace(deduplicated);

      setLocalError(checkLocalError(cleaned, true));
      setValue(cleaned);
    };

    const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const targetValue = e.target.value;
      const convertedToArray = convertEventTargetValueToArray(targetValue);
      const arrayRespectingMaxUrls = applyMaxUrls(convertedToArray);

      setLocalError(checkLocalError(arrayRespectingMaxUrls));
      setValue(arrayRespectingMaxUrls);
    };

    return (
      <TextField
        label={label}
        ariaLabel={!label ? traductions[language].ariaLabel || ariaLabel : undefined}
        disabled={disabled}
        error={getError()}
        fullWidth={fullWidth}
        helperText={getHelperText()}
        maxRows={maxRows}
        minRows={minRows}
        multiline
        onBlur={(e) => onBlur(e)}
        onChange={(e) => onChange(e)}
        placeholder={getPlaceholder()}
        readOnly={readOnly}
        ref={ref}
        value={arrayToStringValue}
        sx={sx}
      />
    );
  }
);
