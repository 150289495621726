import { IconButton as MUIIconButton, IconButtonProps as MUIIconButtonProps } from "@mui/material";
import NextLink, { LinkProps as NextLinkProps } from "next/link";
import { HTMLAttributeAnchorTarget, forwardRef } from "react";
import { MUISizesConverter } from "../../constants";
import { getDoLinearGradient } from "../../doTheme";
import { ButtonColor, ButtonSize } from "../../types";

export type IconButtonProps = Omit<MUIIconButtonProps, "size" | "color" | "href"> &
  Pick<NextLinkProps, "as" | "prefetch"> & {
    href?: NextLinkProps["href"];
    size?: ButtonSize;
    color?: ButtonColor;
    target?: HTMLAttributeAnchorTarget | undefined;
    nextLink?: boolean;
    variant?: "standard" | "filled";
  };
export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      children,
      sx,
      size = "md",
      color = "primary",
      href,
      as,
      prefetch,
      nextLink = true,
      variant = "standard",
      ...props
    },
    ref
  ) => {
    const commonProps: MUIIconButtonProps & {
      href?: NextLinkProps["href"];
    } = {
      size: MUISizesConverter[size],
      color: color === "gradient" ? "inherit" : color,
      sx: {
        ...(color === "gradient" && {
          background: getDoLinearGradient(110),
          color: "white",
          "&:hover": {
            background: getDoLinearGradient("br"),
          },
        }),
        ...(color !== "gradient" && color !== "inherit" && variant === "filled"
          ? {
              backgroundColor: `${color}.main`,
              color: "white",
              "&:hover": { backgroundColor: `${color}.main` },
            }
          : undefined),
        ...sx,
      },
      ...props,
      ref,
    };

    if (nextLink && href) {
      return (
        <NextLink href={href} as={as} prefetch={prefetch} passHref legacyBehavior>
          <MUIIconButton ref={ref} {...commonProps}>
            {children}
          </MUIIconButton>
        </NextLink>
      );
    }

    if (href) {
      if (typeof href !== "string") {
        throw new Error("Button created with non-string href");
      }

      commonProps.href = href;
    }

    return (
      <MUIIconButton ref={ref} {...commonProps}>
        {children}
      </MUIIconButton>
    );
  }
);
