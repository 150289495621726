import { IntegrationType } from "../DataLoader";

type getCurrentIntegrationTypeArgs = {
  currentIntegrationTypeFromPropsOrSessionStorage: IntegrationType | null;
};

export const getCurrentIntegrationType = ({
  currentIntegrationTypeFromPropsOrSessionStorage,
}: getCurrentIntegrationTypeArgs): IntegrationType => {
  let currentIntegrationType: IntegrationType =
    currentIntegrationTypeFromPropsOrSessionStorage || IntegrationType.VOICE;
  if (!Object.values(IntegrationType).includes(currentIntegrationType))
    currentIntegrationType = IntegrationType.VOICE;
  return currentIntegrationType;
};

export const getMongoIdFromGlobalId = (globalId: string) => {
  return Buffer.from(globalId, "base64").toString().split(":")[1];
};
