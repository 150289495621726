import { ScenarioVersionChildSolutionActionLiveChat } from "@dial-once/ich-seed";

export enum SolutionActionType {
  app = "app",
  callback = "callback",
  chatbot = "chatbot",
  contactForm = "contactForm",
  displayContent = "displayContent",
  downloadDocument = "downloadDocument",
  email = "email",
  faq = "faq",
  fax = "fax",
  form = "form",
  liveChat = "liveChat",
  physicalLocation = "physicalLocation",
  postalMail = "postalMail",
  ivrCallOriented = "ivrCallOriented",
  ivrCallHead = "ivrCallHead",
  internalFAQ = "internalFAQ",
  store = "store",
  contextualizedRedirection = "contextualizedRedirection",
  customerArea = "customerArea",
  iFrame = "iFrame",
}

export enum OpenType {
  SameWindow = "SameWindow",
  NewWindow = "NewWindow",
}

export enum SolutionOS {
  android = "Android",
  ios = "iOS",
}

export type ScenarioVersionChildSolutionActionApp = {
  iosLink: string;
  androidLink: string;
  introductionText: string;
};

export type ScenarioVersionChildSolutionUrl = {
  url: string;
};

export type ScenarioVersionChildSolutionActionEmail = {
  to: string;
  subject: string;
  body: string;
  cc: string[];
  bcc: string[];
};

export type ScenarioVersionChildSolutionActionInternalFaq = {
  pageKey: string;
};

export type ScenarioVersionChildSolutionActionPhoneNumber = {
  phoneNumber: string;
  isDigicall: boolean;
  phoneCardText: string;
  isFastDialEnabled: boolean;
};

export type ScenarioVersionChildSolutionActionPostalMailOrLocation = {
  address1: string;
  address2: string;
  postalCode: string;
  city: string;
  country: string;
};

export type ScenarioVersionChildSolutionActionStore = {
  playStoreLink: string;
  appStoreLink: string;
};

export type ScenarioVersionChildSolutionActionContextualizedRedirection = {
  location: string;
  openType: OpenType;
};

export type ScenarioVersionChildSolutionIFrame = {
  url: string;
  isFastform: boolean;
};

export type ScenarioVersionChildSolutionSpecific =
  | ScenarioVersionChildSolutionUrl
  | ScenarioVersionChildSolutionActionLiveChat
  | ScenarioVersionChildSolutionActionPhoneNumber
  | ScenarioVersionChildSolutionActionApp
  | ScenarioVersionChildSolutionActionStore
  | ScenarioVersionChildSolutionActionEmail
  | ScenarioVersionChildSolutionActionPostalMailOrLocation
  | ScenarioVersionChildSolutionActionInternalFaq
  | ScenarioVersionChildSolutionActionContextualizedRedirection
  | ScenarioVersionChildSolutionIFrame;

export type ScenarioVersionChildSolutionWithActionType = {
  actionType: SolutionActionType;
} & ScenarioVersionChildSolutionSpecific;

/*
 If actionType = app :
iosLink: string
androidLink: string
introductionText: string
os: string (“iOS”, “Android” ou “other”)

If actionType = callback :
url: string

If actionType = chatbot :
url: string

If actionType = contactForm :
url: string

If actionType = contextualizedRedirection :
openType: string
location: string

If actionType = displayContent or downloadDocument or customerArea:
url: string

If actionType = email :
to: string
subject: string
body: string
cc : string
bdd: string

If actionType = FAQ :
url: string

If actionType = Form :
url: string

If actionType = internal FAQ
pageKey: string

If actionType = ivrCallHead or ivrCallOriented
url: string

If actionType = liveChat
url: string
sid: string
onlineId: string
offlineId: string
onlineContent: string
offlineContent: string

If actionType = postalMail
address1: string
address2: string
postalCode: string
city: string
country: string

If actionType = store
playStoreLink: string
appStoreLink: string
os: string (“iOS”, “Android” ou “other”)

*/

// TODO : below - legacy, to replace

type SolutionActionPostalAddress = {
  type: "ScenarioVersionChildSolutionActionPostalMail";
  address1?: string;
  address2?: string;
  postalCode?: string;
  city?: string;
  country?: string;
};

type SolutionActionPhoneNumber = {
  type:
    | "ScenarioVersionChildSolutionActionIvrCallHead"
    | "ScenarioVersionChildSolutionActionIvrCallOriented";
  phoneNumber: string;
  isDigicall: boolean;
};

export type SolutionAction =
  | SolutionActionPostalAddress
  | SolutionActionPhoneNumber
  | ScenarioVersionChildSolutionActionApp
  | ScenarioVersionChildSolutionActionStore;

export type Solution = {
  data: {
    solution: {
      id: string;
      action: SolutionAction;
    };
  };
};
