import { NextRouter } from "next/router";
import { NextPage } from "next/types";
import { useEffect } from "react";
import {
  ApiScenarioPageChildCommon,
  ApiScenarioPageSolution,
} from "../../types/api/scenarioVersionChildren";
import { PushWrapperFn } from "../../types/scenarioAnalytics";
import { ContextKeysRecord, DynamicUrls } from "../../types/scenarioDetails";
import { STORAGE_ANALYTIC_ID_KEY, STORAGE_SESSION_ID_KEY } from "../constants";
import { FilteredResultType } from "../DataLoader";
import { getInSessionStorage } from "../helpers/analytics/sessionStorage";
import { runTransformationFunction, TransformationFunctionResult } from "../helpers/context";
import handleClickSolution, { HandleClickSolutionArgs } from "../helpers/node/handleClickSolution";
import { parseSolutionActionFromApi } from "../helpers/scenarioPageLoader/parseSolution";
import { renderElements } from "../helpers/scenarioPageLoader/renderElements";
import useIsSingleSolution from "../hooks/useIsSingleSolution";
import { context } from "../stores/scenarioContentContext";
import PageContainer from "../templates/PageContainer";

type ElementsPageProps = {
  branch: string;
  contextRecord?: ContextKeysRecord;
  currentIntegrationType: string;
  dynamicUrls: DynamicUrls;
  filteredResult: FilteredResultType;
  router: NextRouter;
  routerPush: PushWrapperFn;
  scenarioId?: string;
  shortCode?: string;
};

const ElementsPage: NextPage<ElementsPageProps> = ({
  branch,
  contextRecord,
  currentIntegrationType,
  dynamicUrls,
  filteredResult,
  routerPush,
  scenarioId,
  shortCode,
}) => {
  const isSingleSolution = useIsSingleSolution(filteredResult.scenarioData);

  useEffect(() => {
    if (!isSingleSolution) {
      return;
    }
    const handleSingleSolution = async () => {
      try {
        const child = filteredResult.scenarioData.children[0];
        const {
          transformFct,
          type,
          view,
          action: actionFromApi,
        } = child as ApiScenarioPageChildCommon & ApiScenarioPageSolution;
        let transformedParameters: TransformationFunctionResult | undefined;

        if (transformFct) {
          const action = parseSolutionActionFromApi(actionFromApi);

          transformedParameters = runTransformationFunction({
            serializedFunction: transformFct,
            context: context.value,
            type,
            view,
            action: action || undefined,
            analyticSessionId: getInSessionStorage(STORAGE_ANALYTIC_ID_KEY) || undefined,
            scenarioSessionId: getInSessionStorage(STORAGE_SESSION_ID_KEY) || undefined,
          });
        }
        const solution = child as ApiScenarioPageSolution;
        const clickArgs: HandleClickSolutionArgs = {
          ...(solution.action as HandleClickSolutionArgs),
          ...transformedParameters?.actionValues,
          actionType: solution.actionType,
          id: child.id,
          scenarioId,
          shortCode,
          isAutomaticClick: true,
          branch,
          dynamicUrls,
          routerPush,
        };
        //TMP hotfix GMF bug -- to be investigated
        void handleClickSolution(clickArgs);
      } catch (error: unknown) {
        console.error(error);
      }
    };
    void handleSingleSolution();
  }, [
    branch,
    dynamicUrls,
    filteredResult.scenarioData.children,
    isSingleSolution,
    routerPush,
    scenarioId,
    shortCode,
  ]);

  if (isSingleSolution) {
    return null;
  }

  return (
    <PageContainer>
      {renderElements({
        branch,
        contextRecord,
        currentIntegrationType,
        dynamicUrls,
        elements: filteredResult.scenarioData.children,
        routerPush,
        scenarioId,
        shortCode,
      })}
    </PageContainer>
  );
};

export default ElementsPage;
