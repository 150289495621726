import { NodeType } from "./node";
import { ScenarioVersionChildSolutionSpecific, SolutionActionType } from "./solutions";

export enum ScenarioVersionChildType {
  Accordion = 1,
  Block = 2,
  Html = 3,
  Intent = 4,
  Solution = 5,
  Dropdown = 6,
}

const ScenarioVersionChildContainerTypes: ScenarioVersionChildType[] = [
  ScenarioVersionChildType.Accordion,
  ScenarioVersionChildType.Block,
  ScenarioVersionChildType.Dropdown,
];

export type ContextOperations = {
  keysToUpsert: Array<{ key: string; value: string }>;
  keysToDelete: Array<string>;
};

export enum ToPageType {
  page = 1,
  solution = 2,
}

export type ApiScenarioPageToPage = {
  key: string;
  id: string;
  type: ToPageType;
};

export type ApiScenarioPageToSolution = {
  id: string;
  type: ToPageType;
  solution:
    | ({
        actionType: SolutionActionType;
      } & ScenarioVersionChildSolutionSpecific)
    | null;
  key?: string;
};

export type ApiScenarioPageIntentTo = ApiScenarioPageToPage | ApiScenarioPageToSolution | null;

export type ApiScenarioPageAccordion = {
  className: string;
  openByDefault: boolean;
  children: ApiScenarioPageChildDocument[];
  title: string;
};

export type ApiScenarioPageBlock = {
  className: string;
  children: ApiScenarioPageChildDocument[];
};

export type ApiScenarioPageDropdown = {
  className: string;
  buttonText: string;
  placeholder: string;
  introduction: string;
  children: ApiScenarioPageChildDocument[];
};

export type ApiScenarioPageIntent = {
  to: ApiScenarioPageIntentTo;
  view: string;
  title: string;
  contextOperations?: ContextOperations;
};

export type ApiScenarioPageSolution = {
  actionType: SolutionActionType;
  action: ScenarioVersionChildSolutionSpecific;
  view: string;
  title: string;
};

export type ApiScenarioPageHtml = {
  className: string;
  view: string;
};

export type ApiScenarioPageChildSpecific =
  | ApiScenarioPageAccordion
  | ApiScenarioPageBlock
  | ApiScenarioPageDropdown
  | ApiScenarioPageIntent
  | ApiScenarioPageSolution
  | ApiScenarioPageHtml;

export type ApiScenarioPageChildCommon = {
  id: string;
  name: string;
  delay: number;
  isActiveInTimetable: boolean;
  type: NodeType;
  transformFct?: string;
  contextId?: string;
};

export type ApiScenarioPageChildDocument = ApiScenarioPageChildCommon &
  ApiScenarioPageChildSpecific;

export { ScenarioVersionChildContainerTypes };
