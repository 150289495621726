export enum UserAgentBrowser {
  Unknown = 0,
  AmazonSilk = 1,
  AndroidBrowser = 2,
  Bada = 3,
  BlackBerry = 4,
  Chrome = 5,
  Chromium = 6,
  Electron = 7,
  Epiphany = 8,
  Firefox = 9,
  Focus = 10,
  Generic = 11,
  GoogleSearch = 12,
  GoogleBot = 13,
  InternetExplorer = 14,
  KMeleon = 15,
  Maxthon = 16,
  MicrosoftEdge = 17,
  MzBrowser = 18,
  NaverWhaleBrowser = 19,
  Opera = 20,
  OperaCoast = 21,
  PhantomJs = 22,
  Puffin = 23,
  QupZilla = 24,
  Qq = 25,
  QqLite = 26,
  Safari = 27,
  Sailfish = 28,
  SamsungInternetForAndroid = 29,
  SeaMonkey = 30,
  Sleipnir = 31,
  Swing = 32,
  Tizen = 33,
  UcBrowser = 34,
  Vivaldi = 35,
  WebOsBrowser = 36,
  WeChat = 37,
  YandexBrowser = 38,
  Roku = 39,
}

export enum UserAgentEngine {
  Unknown = 0,
  EdgeHtml = 1,
  Blink = 2,
  Trident = 3,
  Presto = 4,
  Gecko = 5,
  WebKit = 6,
}

export enum UserAgentOs {
  Unknown = 0,
  WindowsPhone = 1,
  Windows = 2,
  MacOs = 3,
  Ios = 4,
  Android = 5,
  WebOS = 6,
  BlackBerry = 7,
  Bada = 8,
  Tizen = 9,
  Linux = 10,
  ChromeOs = 11,
  PlayStation4 = 12,
  Roku = 13,
}

export enum UserAgentPlatform {
  Unknown = 0,
  Tablet = 1,
  Mobile = 2,
  Desktop = 3,
  Tv = 4,
}

export enum UserAgentVendor {
  Unknown = 0,
  Apple = 1,
  BlackBerry = 2,
  Microsoft = 3,
  Google = 4,
  Huawei = 5,
  Nexus = 6,
  Amazon = 7,
}
