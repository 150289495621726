const style = `
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#ich-loader {
  display: none;
}

#ich-loader.active {
  display: block;
}

.ich-accordion {
  margin-bottom: 10px;
  background-color: #eee;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion-title {
  color: #444;
  cursor: pointer;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
  border-bottom: #212c4d solid 4px;
  position: relative;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.accordion-title.active,
.accordion-title:hover {
  background-color: #ccc;
}

/* Style the accordion panel. Note: hidden by default */
.accordion-panel {
  padding: 16px;
  display: none;
  overflow: hidden;
}

.accordion-panel.active {
  display: block;
}

.accordion-title:after {
  position: absolute;
  display: block;
  content: '';
  width: 17px;
  height: 17px;
  top: 15px;
  right: 18px;
  border-style: solid;
  border-width: 0.25em 0.25em 0 0;
  transform: rotate(135deg);
}

.accordion-title.active:after {
  top: 24px;
  transform: rotate(-45deg);
}

#ich-close {
  display: none;
}

#ich-home {
  display: none;
}

/* Back button */
#ich-back-button {
  position: absolute;
  left: 15px;
  top: 30px;
  min-height: 42px;
  font-size: 20px;
  z-index: 15;
  outline: none;
  display: flex;
  align-items: center;
  background: #ff733b;
  padding: 8px 12px 8px 0;
  border-radius: 100px;
}

#ich-back-button:hover {
  background: white;
}

#ich-back-button-icon {
  width: 1rem;
  height: 1rem;
  fill: white;
  color: white;
  margin-left: 12px;
}

#ich-back-button-label {
  font-size: 17px;
  color: white;
  font-weight: 500;
  padding-left: 12px;
}

#ich-back-button:hover #ich-back-button-icon,
#ich-back-button:hover #ich-back-button-label {
  color: #341038;
  fill: #341038;
}

/* Close button */
#ich-close-button {
  position: absolute;
  right: 15px;
  top: 30px;
  min-height: 42px;
  font-size: 20px;
  z-index: 15;
  outline: none;
  display: flex;
  align-items: center;
  background: #ff733b;
  padding: 8px 12px 8px 0;
  border-radius: 100px;
}

#ich-close-button:hover {
  background: white;
}

#ich-close-button-icon {
  width: 1rem;
  height: 1rem;
  fill: white;
  color: white;
  margin-left: 12px;
}

#ich-close-button-label {
  font-size: 17px;
  color: white;
  font-weight: 500;
  padding-left: 12px;
}

#ich-close-button:hover #ich-close-button-icon,
#ich-close-button:hover #ich-close-button-label {
  color: #341038;
  fill: #341038;
}

.dropdown-select {
  background-image: url('data:image/svg+xml;charset=utf-8,%3Csvg xmlns=http://www.w3.org/2000/svg viewBox=0 0 20 20 fill=none%3E%3Cpath d=M7 7l3-3 3 3m0 6l-3 3-3-3 stroke=%239fa6b2 stroke-width=1.5 stroke-linecap=round stroke-linejoin=round/%3E%3C/svg%3E');
  appearance: none;
  color-adjust: exact;
  background-repeat: no-repeat;
  background-color: #fff;
  border-color: #d2d6dc;
  border-width: 1px;
  border-radius: 0.375rem;
  padding: 0.5rem 2.5rem 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  background-position: right 0.5rem center;
  background-size: 1.5em 1.5em;
}

@media (max-width: 480px) {
  #ich-back-button-label {
    display: none;
  }

  #ich-close-button-label {
    display: none;
  }
}
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#ich-loader {
  display: none;
}

#ich-loader.active {
  display: block;
}

.ich-accordion {
  margin-bottom: 10px;
  background-color: #eee;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion-title {
  color: #444;
  cursor: pointer;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
  border-bottom: #212c4d solid 4px;
  position: relative;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.accordion-title.active,
.accordion-title:hover {
  background-color: #ccc;
}

/* Style the accordion panel. Note: hidden by default */
.accordion-panel {
  padding: 16px;
  display: none;
  overflow: hidden;
}

.accordion-panel.active {
  display: block;
}

.accordion-title:after {
  position: absolute;
  display: block;
  content: '';
  width: 17px;
  height: 17px;
  top: 15px;
  right: 18px;
  border-style: solid;
  border-width: 0.25em 0.25em 0 0;
  transform: rotate(135deg);
}

.accordion-title.active:after {
  top: 24px;
  transform: rotate(-45deg);
}

#ich-close {
  display: none;
}

#ich-home {
  display: none;
}

/* Back button */
#ich-back-button {
  position: absolute;
  left: 15px;
  top: 30px;
  min-height: 42px;
  font-size: 20px;
  z-index: 15;
  outline: none;
  display: flex;
  align-items: center;
  background: #ff733b;
  padding: 8px 12px 8px 0;
  border-radius: 100px;
}

#ich-back-button:hover {
  background: white;
}

#ich-back-button-icon {
  width: 1rem;
  height: 1rem;
  fill: white;
  color: white;
  margin-left: 12px;
}

#ich-back-button-label {
  font-size: 17px;
  color: white;
  font-weight: 500;
  padding-left: 12px;
}

#ich-back-button:hover #ich-back-button-icon,
#ich-back-button:hover #ich-back-button-label {
  color: #341038;
  fill: #341038;
}

/* Close button */
#ich-close-button {
  position: absolute;
  right: 15px;
  top: 30px;
  min-height: 42px;
  font-size: 20px;
  z-index: 15;
  outline: none;
  display: flex;
  align-items: center;
  background: #ff733b;
  padding: 8px 12px 8px 0;
  border-radius: 100px;
}

#ich-close-button:hover {
  background: white;
}

#ich-close-button-icon {
  width: 1rem;
  height: 1rem;
  fill: white;
  color: white;
  margin-left: 12px;
}

#ich-close-button-label {
  font-size: 17px;
  color: white;
  font-weight: 500;
  padding-left: 12px;
}

#ich-close-button:hover #ich-close-button-icon,
#ich-close-button:hover #ich-close-button-label {
  color: #341038;
  fill: #341038;
}

/* This is needed to replicate the default style of the dropdown put by emotion in the previous builder*/
select {
  padding: 0;
  line-height: inherit;
  color: inherit;
  text-transform: none;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

select,
option,
button {
  border-width: 0;
  border-style: solid;
  box-sizing: border-box;
}

.dropdown-select {
  background-image: url('data:image/svg+xml;charset=utf-8,%3Csvg xmlns=http://www.w3.org/2000/svg viewBox=0 0 20 20 fill=none%3E%3Cpath d=M7 7l3-3 3 3m0 6l-3 3-3-3 stroke=%239fa6b2 stroke-width=1.5 stroke-linecap=round stroke-linejoin=round/%3E%3C/svg%3E');
  appearance: none;
  color-adjust: exact;
  background-repeat: no-repeat;
  background-color: #fff;
  border-color: #d2d6dc;
  border-width: 1px;
  border-radius: 0.375rem;
  padding: 0.5rem 2.5rem 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  background-position: right 0.5rem center;
  background-size: 1.5em 1.5em;
}

button {
  padding: 0;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  background: transparent;
  padding: 0;
  text-transform: none;
  overflow: visible;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

.ich-dropdown-button {
  transition-duration: 0.15s;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow,
    transform;
  line-height: 1rem;
  border-radius: 0.375rem;
  font-weight: 500;
  outline: none;
  cursor: pointer;
  display: inline-block;
  border-width: 1px;
  border-color: transparent;
  font-size: 0.875rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background: #4b5563;
  color: #ffffff;
}

.ich-dropdown-button:active {
  background: #374151;
}

.ich-dropdown-button:hover {
  background: #6b7280;
}

@media (max-width: 480px) {
  #ich-back-button-label {
    display: none;
  }

  #ich-close-button-label {
    display: none;
  }
}

`;

export default style;
