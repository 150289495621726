export enum ScenarioAnalyticsDocumentActionType {
  Load = 1,
  Push = 2,
  // Replace = 3,
  // Pop = 4,
  // Unload = 5,
  Solution = 6,
  Back = 7,
  IAdvize = 8,
  Close = 9,
}

export type PushWrapperArgs = {
  fromIntentId?: string;
  toPageKey?: string;
};

export type PushWrapperFn = (url: string, opt?: PushWrapperArgs) => Promise<void>;
