import { forwardRef } from "react";
import {
  Alert as MUIAlert,
  AlertTitle as MUIAlertTitle,
  Snackbar as MUISnackbar,
  AlertProps,
  SvgIconTypeMap,
} from "@mui/material";

import type {
  ToastHorizontalPosition,
  ToastSeverity,
  ToastVariant,
  ToastVerticalPosition,
} from "../../types";

import { OverridableComponent } from "@mui/material/OverridableComponent";
import { Fade } from "@mui/material";

export type ToastProps = Omit<AlertProps, "variant" | "severity"> & {
  open: boolean;
  variant?: ToastVariant;
  severity?: ToastSeverity;
  alertMessage: string;
  alertTitle?: string;
  verticalPosition?: ToastVerticalPosition;
  horizontalPosition?: ToastHorizontalPosition;
  icon?: OverridableComponent<SvgIconTypeMap<"svg">>;
};

export const Toast = forwardRef<HTMLElement, ToastProps>(
  (
    {
      sx,
      variant = "standard",
      severity = "success",
      verticalPosition = "top",
      horizontalPosition = "right",
      alertMessage,
      alertTitle,
      icon,
      open,
      onClose,
      ...props
    },
    ref
  ) => {
    return (
      <MUISnackbar
        anchorOrigin={{ vertical: verticalPosition, horizontal: horizontalPosition }}
        open={open}
        TransitionComponent={Fade}
      >
        <MUIAlert icon={icon} variant={variant} severity={severity} {...props}>
          {alertTitle && <MUIAlertTitle> {alertTitle} </MUIAlertTitle>}
          {alertMessage}
        </MUIAlert>
      </MUISnackbar>
    );
  }
);

export default Toast;
