type HandleLiveChatActionArgs = {
  url: string | undefined;
  openInCurrentTab?: boolean;
};

export const handleLiveChatAction = ({ url, openInCurrentTab }: HandleLiveChatActionArgs) => {
  if (url?.length) {
    window.open(url, openInCurrentTab ?? false ? "_self" : "_blank");
    return true;
  } else {
    return false;
  }
};
