import { FC } from "react";
import HomeIcon from "@mui/icons-material/Home";

const linkCss = {
  marginBottom: "1rem",
  fontWeight: "bold",
  display: "flex",
  alignItems: "center",
  paddingBottom: "1rem",
  borderBottom: "1px dotted rgba(255, 255, 255, 0.5)",
};

const iconCss = {
  width: "1rem",
  height: "1rem",
  marginRight: "0.5rem",
};

const accueilCss = {
  cursor: "pointer",
};

export type Props = {
  onClick: () => void;
};

const HomeLink: FC<Props> = ({ onClick }) => {
  return (
    <a onClick={onClick} style={linkCss}>
      <HomeIcon sx={iconCss} />
      <div style={accueilCss}>Accueil</div>
    </a>
  );
};

export default HomeLink;
