import { IntegrationType } from "./integration";
import { IntentType } from "./intent";
import SolutionType from "./solution";

export enum NodeType {
  solution = "solution",
  intent = "intent",
  block = "block",
  accordion = "accordion",
  dropdown = "dropdown",
  html = "HTML",
}

export type HtmlNodeType = {
  id: string;
  type: NodeType.html;
  name: string;
  title: string;
  className: string | null;
  integrationType: IntegrationType;
  enabled: boolean;
  delay: number;
  timetable: boolean;
  view: string;
  parentId: string;
  position: number;
};

export type DropdownNodeType = {
  id: string;
  type: NodeType.dropdown;
  name: string;
  title: string;
  integrationType: IntegrationType;
  enabled: boolean;
  delay: number;
  timetable: boolean;
  buttonText: string;
  placeholder: string;
  introduction: string;
  parentId: string;
  position: number;
  child: (IntentType | SolutionType)[];
};

export type BlockNodeType = {
  id: string;
  type: NodeType.block;
  name: string;
  title: string;
  integrationType: IntegrationType;
  enabled: boolean;
  delay: number;
  timetable: boolean;
  parentId: string;
  position: number;
  child: (IntentType | SolutionType)[];
};

export type AccordionNodeType = {
  id: string;
  type: NodeType.accordion;
  name: string;
  title: string;
  className: string;
  openByDefault: boolean;
  integrationType: IntegrationType;
  enabled: boolean;
  delay: number;
  timetable: boolean;
  parentId: string;
  position: number;
  child: (IntentType | SolutionType)[];
};

export type GlobalNode =
  | IntentType
  | SolutionType
  | HtmlNodeType
  | DropdownNodeType
  | BlockNodeType
  | AccordionNodeType;
