import clsx from "clsx";
import { FC, useEffect, useRef } from "react";

type HtmlNodeProps = {
  className?: string | null;
  view: string;
};

const HtmlNode: FC<HtmlNodeProps> = ({ className, view }) => {
  const classNames = clsx("ich-html", className);
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fragment = document.createRange().createContextualFragment(view);
    if (divRef.current) divRef.current.append(fragment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <div ref={divRef} className={classNames} />;
};

export default HtmlNode;
