import Bowser from "bowser";

import {
  cleanupValue,
  getBrowserType,
  getEngineType,
  getOsType,
  getPlatformType,
  getVendorType,
  getVersion,
} from "./transforms";
import {
  UserAgentBrowser,
  UserAgentEngine,
  UserAgentOs,
  UserAgentPlatform,
  UserAgentVendor,
} from "./types";

export function parseUserAgent(userAgent = "") {
  if (userAgent.length === 0) {
    return {
      browser: { type: UserAgentBrowser.Unknown, version: null },
      engine: { type: UserAgentEngine.Unknown },
      os: { type: UserAgentOs.Unknown },
      platform: {
        type: UserAgentPlatform.Unknown,
        vendor: UserAgentVendor.Unknown,
      },
    };
  }
  const result = Bowser.parse(userAgent);
  const { browser, engine, os, platform } = result;
  return {
    browser: {
      type: getBrowserType(browser.name),
      version: getVersion(browser.version),
    },
    engine: { type: getEngineType(engine.name) },
    os: { type: getOsType(os.name), version: getVersion(os.version) },
    platform: {
      type: getPlatformType(platform.type),
      vendor: getVendorType(platform.vendor),
    },
  };
}

export function parseUserAgentAsString(userAgent = "") {
  if (userAgent.length === 0) {
    return {
      browser: { name: null, version: null },
      engine: { name: null },
      os: { name: null },
      platform: { type: null, vendor: null },
    };
  }
  const result = Bowser.parse(userAgent);
  const { browser, engine, os, platform } = result;
  return {
    browser: {
      name: cleanupValue(browser.name),
      version: getVersion(browser.version),
    },
    engine: { name: cleanupValue(engine.name) },
    os: { name: cleanupValue(os.name), version: getVersion(os.version) },
    platform: {
      type: cleanupValue(platform.type),
      vendor: cleanupValue(platform.vendor),
    },
  };
}
