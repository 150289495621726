export type IntegrationType = {
  voice: boolean;
  widget: boolean;
  hyperlink: boolean;
};

export enum IntegrationTypeEnum {
  voice = "voice",
  widget = "widget",
  hyperlink = "hyperlink",
}
