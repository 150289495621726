import { forwardRef } from "react";
import {
  DialogActions as MUIDialogActions,
  DialogActionsProps as MUIDialogActionsProps,
} from "@mui/material";

export type DialogActionsProps = MUIDialogActionsProps;

export const DialogActions = forwardRef<HTMLDivElement, DialogActionsProps>((props, ref) => {
  return <MUIDialogActions ref={ref} {...props} />;
});
