import type { PropsWithChildren, ReactNode } from "react";
import { forwardRef } from "react";

type SrOnlyProps = {
  children?: ReactNode;
} & PropsWithChildren;

export const SrOnly = forwardRef<HTMLSpanElement, SrOnlyProps>(({ children }, ref) => {
  return (
    <span
      ref={ref}
      style={{
        position: "absolute",
        width: "1px",
        height: "1px",
        padding: 0,
        margin: "-1px",
        overflow: "hidden",
        clip: "rect(0, 0, 0, 0)",
        whiteSpace: "nowrap",
        borderWidth: 0,
      }}
    >
      {children}
    </span>
  );
});
