import type { MenuItemData } from "../components";

export const updateCheckboxes = (
  items: MenuItemData[],
  path: number[],
  isChecked: boolean
): MenuItemData[] => {
  const updateChildrenRecursive = (
    children: MenuItemData[],
    isChecked: boolean
  ): MenuItemData[] => {
    return children.map((child) => ({
      ...child,
      checked: isChecked,
      indeterminate: false,
      items: child.items ? updateChildrenRecursive(child.items, isChecked) : [],
    }));
  };

  // Find and update the element at the given path
  const findAndUpdateTargetedElement = (
    items: MenuItemData[],
    path: number[],
    isChecked: boolean
  ) => {
    const indices = [...path];
    const lastIndex = indices.pop();

    let current = items;
    for (const index of indices) {
      const newCurrent = current[index].items;

      if (newCurrent !== undefined) {
        current = newCurrent;
      }
    }

    // Update the element at the given path
    if (lastIndex !== undefined) {
      current[lastIndex].checked = isChecked;
      current[lastIndex].indeterminate = false;

      const newCurrent = current[lastIndex].items;
      if (newCurrent) {
        current[lastIndex].items = updateChildrenRecursive(newCurrent, isChecked);
      }
    }

    return items;
  };

  // Update all parents based on their children
  const updateParents = (items: MenuItemData[], path: number[]) => {
    const currentPath = path.slice(); // Copy the path to avoid mutation

    while (currentPath.length > 0) {
      currentPath.pop();

      // Update the parent based on its children
      if (currentPath.length > 0) {
        const parentIndex = currentPath[currentPath.length - 1];
        let parent = items;

        for (let i = 0; i < currentPath.length - 1; i++) {
          const newParent = parent[currentPath[i]].items;
          if (newParent) {
            parent = newParent;
          }
        }

        const children = parent[parentIndex].items;
        const allChecked = children?.every((child) => child.checked);
        const allUnchecked = children?.every((child) => !child.checked && !child.indeterminate);

        parent[parentIndex].checked = allChecked;
        parent[parentIndex].indeterminate = !allChecked && !allUnchecked;
      }
    }
  };

  items = findAndUpdateTargetedElement([...items], path, isChecked);
  updateParents(items, path);

  return items;
};
