import { StatusCodes } from "http-status-codes";

// When adding to this enum, don't forget to add the corresponding status code in the object below
export enum ErrorEnum {
  ANALYTICS_FAIL = "ANALYTICS_FAIL",
  BROWSER_SESSION_INSERT_FAIL = "BROWSER_SESSION_INSERT_FAIL",
  CURRENT_SCENARIO_ID_NOT_EXIST = "CURRENT_SCENARIO_ID_NOT_EXIST",
  INTEGRATION_TYPE_NOT_MATCH = "INTEGRATION_TYPE_NOT_MATCH", // solution page
  INVALID_ANALYTIC_TYPE = "INVALID_ANALYTIC_TYPE",
  INVALID_INPUT = "INVALID_INPUT",
  INVALID_SHORTENED_URL = "INVALID_SHORTENED_URL",
  INVALID_SHORTCODE = "INVALID_SHORTCODE",
  INVALID_SCENARIO_ID = "INVALID_SCENARIO_ID",
  INVALID_SCENARIO_SESSION_ID = "INVALID_SCENARIO_SESSION_ID",
  INVALID_SOLUTION_ID = "INVALID_SOLUTION_ID",
  INVALID_SOLUTION_TYPE = "INVALID_SOLUTION_TYPE",
  MAINPAGE_NOT_EXIST = "MAINPAGE_NOT_EXIST",
  ROUTE_ONLY_SUPPORTS_GET_METHOD = "ROUTE_ONLY_SUPPORTS_GET_METHOD",
  ROUTE_ONLY_SUPPORTS_POST_METHOD = "ROUTE_ONLY_SUPPORTS_POST_METHOD",
  ROUTE_ONLY_SUPPORTS_GET_POST_METHODS = "ROUTE_ONLY_SUPPORTS_GET_POST_METHODS",
  SCENARIO_ID_NOT_DEFINED = "SCENARIO_ID_NOT_DEFINED",
  SCENARIO_ID_OR_SHORTCODE_NOT_PROVIDED = "SCENARIO_ID_OR_SHORTCODE_NOT_PROVIDED",
  SCENARIO_NOT_EXIST = "SCENARIO_NOT_EXIST",
  SCENARIO_SESSION_INSERT_FAIL = "SCENARIO_SESSION_INSERT_FAIL",
  SCENARIO_SESSION_UPDATE_FAIL = "SCENARIO_SESSION_UPDATE_FAIL",
  SCENARIO_VERSION_CHILD_NOT_EXIST = "SCENARIO_VERSION_CHILD_NOT_EXIST",
  SCENARIO_VERSION_CHILD_IS_NOT_A_SOLUTION = "SCENARIO_VERSION_CHILD_IS_NOT_A_SOLUTION",
  SCENARIO_VERSION_CHILD_SOLUTION_ACTION_TYPE_NOT_PROVIDED = "SCENARIO_VERSION_CHILD_SOLUTION_ACTION_TYPE_NOT_PROVIDED",
  SCENARIO_VERSION_CONTEXT_ELEMENT_NOT_EXIST = "SCENARIO_VERSION_CONTEXT_ELEMENT_NOT_EXIST",
  SCENARIO_VERSION_ID_NOT_EXIST = "SCENARIO_VERSION_ID_NOT_EXIST",
  SCENARIO_VERSION_ITEM_NOT_EXIST = "SCENARIO_VERSION_ITEM_NOT_EXIST",
  SCENARIO_VERSION_NOT_EXIST = "SCENARIO_VERSION_NOT_EXIST",
  SCENARIO_VERSION_NO_INITIAL_ITEM = "SCENARIO_VERSION_NO_INITIAL_ITEM",
  SOLUTION_ANALYTICS_FAIL = "SOLUTION_ANALYTICS_FAIL",
  SOLUTION_NOT_EXIST = "SOLUTION_NOT_EXIST",
  UNKNOWN_ERROR = "UNKNOWN_ERROR",
  WRONG_ID_SIGNATURE = "WRONG_ID_SIGNATURE",
  PUSH_TO_CONTRACK_FAIL = "PUSH_TO_CONTRACK_FAIL",
  GET_CONTEXT_FAIL = "GET_CONTEXT_FAIL",
  METHOD_NOT_ALLOWED = "METHOD_NOT_ALLOWED",
  CONTEXT_UPDATE_FAIL = "CONTEXT_UPDATE_FAIL",
}

export const mapBetweenErrorStatusAndMessages: { [key: string]: StatusCodes } = {
  [ErrorEnum.ANALYTICS_FAIL]: StatusCodes.FORBIDDEN,
  [ErrorEnum.BROWSER_SESSION_INSERT_FAIL]: StatusCodes.FORBIDDEN,
  [ErrorEnum.INTEGRATION_TYPE_NOT_MATCH]: StatusCodes.NOT_FOUND,
  [ErrorEnum.INVALID_ANALYTIC_TYPE]: StatusCodes.NOT_FOUND,
  [ErrorEnum.INVALID_INPUT]: StatusCodes.NOT_FOUND,
  [ErrorEnum.INVALID_SCENARIO_ID]: StatusCodes.NOT_FOUND,
  [ErrorEnum.INVALID_SCENARIO_SESSION_ID]: StatusCodes.NOT_FOUND,
  [ErrorEnum.INVALID_SHORTENED_URL]: StatusCodes.NOT_FOUND,
  [ErrorEnum.INVALID_SHORTCODE]: StatusCodes.NOT_FOUND,
  [ErrorEnum.INVALID_SOLUTION_ID]: StatusCodes.NOT_FOUND,
  [ErrorEnum.INVALID_SOLUTION_TYPE]: StatusCodes.FORBIDDEN,
  [ErrorEnum.MAINPAGE_NOT_EXIST]: StatusCodes.FORBIDDEN,
  [ErrorEnum.ROUTE_ONLY_SUPPORTS_GET_METHOD]: StatusCodes.METHOD_NOT_ALLOWED,
  [ErrorEnum.ROUTE_ONLY_SUPPORTS_POST_METHOD]: StatusCodes.METHOD_NOT_ALLOWED,
  [ErrorEnum.ROUTE_ONLY_SUPPORTS_GET_POST_METHODS]: StatusCodes.METHOD_NOT_ALLOWED,
  [ErrorEnum.SCENARIO_ID_NOT_DEFINED]: StatusCodes.NOT_FOUND,
  [ErrorEnum.SCENARIO_ID_OR_SHORTCODE_NOT_PROVIDED]: StatusCodes.NOT_FOUND,
  [ErrorEnum.SCENARIO_NOT_EXIST]: StatusCodes.NOT_FOUND,
  [ErrorEnum.SCENARIO_VERSION_CHILD_IS_NOT_A_SOLUTION]: StatusCodes.FORBIDDEN,
  [ErrorEnum.SCENARIO_VERSION_CHILD_NOT_EXIST]: StatusCodes.FORBIDDEN,
  [ErrorEnum.SCENARIO_VERSION_CHILD_SOLUTION_ACTION_TYPE_NOT_PROVIDED]: StatusCodes.NOT_FOUND,
  [ErrorEnum.SCENARIO_VERSION_CONTEXT_ELEMENT_NOT_EXIST]: StatusCodes.FORBIDDEN,
  [ErrorEnum.SCENARIO_VERSION_ID_NOT_EXIST]: StatusCodes.FORBIDDEN,
  [ErrorEnum.SCENARIO_VERSION_ITEM_NOT_EXIST]: StatusCodes.NOT_FOUND,
  [ErrorEnum.SCENARIO_VERSION_NO_INITIAL_ITEM]: StatusCodes.FORBIDDEN,
  [ErrorEnum.SCENARIO_VERSION_NOT_EXIST]: StatusCodes.FORBIDDEN,
  [ErrorEnum.SCENARIO_SESSION_INSERT_FAIL]: StatusCodes.FORBIDDEN,
  [ErrorEnum.SCENARIO_SESSION_UPDATE_FAIL]: StatusCodes.FORBIDDEN,
  [ErrorEnum.SOLUTION_ANALYTICS_FAIL]: StatusCodes.FORBIDDEN,
  [ErrorEnum.SOLUTION_NOT_EXIST]: StatusCodes.FORBIDDEN,
  [ErrorEnum.UNKNOWN_ERROR]: StatusCodes.BAD_REQUEST,
  [ErrorEnum.WRONG_ID_SIGNATURE]: StatusCodes.NOT_FOUND,
  [ErrorEnum.PUSH_TO_CONTRACK_FAIL]: StatusCodes.INTERNAL_SERVER_ERROR,
  [ErrorEnum.GET_CONTEXT_FAIL]: StatusCodes.NOT_FOUND,
  [ErrorEnum.METHOD_NOT_ALLOWED]: StatusCodes.METHOD_NOT_ALLOWED,
  [ErrorEnum.CONTEXT_UPDATE_FAIL]: StatusCodes.NOT_FOUND,
};

export const getErrorStatusCodeFromErrorMessage = (errorMessage: ErrorEnum): StatusCodes =>
  mapBetweenErrorStatusAndMessages[errorMessage as string] || StatusCodes.BAD_REQUEST;
