import { BASE_LABEL_WEIGHT, BASE_PARAGRAPH_WEIGHT } from "../newTheme";

export const variants = {
  label: {
    fontWeight: BASE_LABEL_WEIGHT,
  },
  paragraph: {
    fontWeight: BASE_PARAGRAPH_WEIGHT,
  },
  inherit: {},
};

export const truncateHorizontalStyle = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};

export const truncateVerticalStyle = (numberOfLines: number) => ({
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: numberOfLines,
  whiteSpace: "normal", // to override truncateHorizontalStyle
});

export const loremIpsum =
  "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Velit voluptatem ipsum officia, saepe, quisquam rem labore quam ratione cum explicabo nulla quasi quidem fugiat harum tenetur porro deserunt est a. Deserunt dolor possimus excepturi distinctio, neque eum voluptas vel saepe impedit officia, sit maiores quasi laborum voluptatem vero odit aliquid atque. Quisquam natus debitis omnis quam optio sequi nostrum recusandae. Quibusdam quae ad nam impedit temporibus sunt tenetur non nesciunt. Dicta sint labore, excepturi doloribus esse veritatis at non ad ullam architecto, facere veniam deleniti nisi? Ex sapiente asperiores itaque!";
