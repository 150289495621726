import Script from "next/script";
import { FC, useEffect, useState } from "react";
import { initIAdvize } from "../helpers";
import { IAdvizeScriptObject } from "../../types";

export type Props = {
  currentPageKey: string;
  scripts: IAdvizeScriptObject[];
  shortCode?: string;
};

const ExternalScripts: FC<Props> = ({ currentPageKey, scripts, shortCode }) => {
  const [scriptURL, setScriptURL] = useState<string>();

  // currently, only worked if no more than one script in the page ?
  // If iadvize.sid, it means that we should include the main tag of IAdvize
  const currentScripts = scripts.filter((script) => script.pageKey === currentPageKey);

  useEffect(() => {
    if (currentScripts.length > 0 && currentScripts[0]?.iadvize?.sid) {
      const script = initIAdvize(currentScripts[0]?.iadvize, shortCode);
      setScriptURL(script);
    }
  }, [currentScripts, shortCode]);

  return scriptURL ? <Script src={scriptURL} async /> : null;
};

export default ExternalScripts;
