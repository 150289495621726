export type EmailUrlInput = {
  to: string;
  subject?: string;
  body?: string;
  cc?: string[];
  bcc?: string[];
};

export function encodeEmailUrlPart(name: string, valueOrValues: string | string[]) {
  let value = valueOrValues;

  if (Array.isArray(value)) value = value.join(",");

  return `${name}=${encodeURIComponent(value)}`;
}

export function getEmailUrl(input: EmailUrlInput) {
  const { to, subject, body, cc, bcc } = input;

  const parts = [];

  if (subject != null) parts.push(encodeEmailUrlPart("subject", subject));

  if (body != null) parts.push(encodeEmailUrlPart("body", body));

  if (cc != null && cc.length > 0) parts.push(encodeEmailUrlPart("cc", cc));

  if (bcc != null && bcc.length > 0) parts.push(encodeEmailUrlPart("bcc", bcc));

  return `mailto:${to}?${parts.join("&")}`;
}
