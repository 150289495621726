import { Typography as MUITypography, SxProps, TypographyProps } from "@mui/material";
import { ElementType, forwardRef } from "react";

import { truncateHorizontalStyle, truncateVerticalStyle } from "../../constants";
import type { HeadingComponent, HeadingSize, TextColor } from "../../types";

export type HeadingProps = Omit<TypographyProps, "variant" | "component" | "variant" | "color"> & {
  component: HeadingComponent;
  size?: HeadingSize;
  color?: TextColor;
  truncate?: boolean;
  numberOfLines?: number;
};

export const Heading = forwardRef<HTMLElement, HeadingProps>(
  (
    { component, size = "2xl", color = "black", truncate, numberOfLines = 0, sx, ...props },
    ref
  ) => {
    return (
      <MUITypography
        ref={ref}
        color={color}
        variant={size}
        component={component as ElementType}
        sx={
          {
            ...(truncate ? truncateHorizontalStyle : {}),
            ...(numberOfLines > 0 ? truncateVerticalStyle(numberOfLines) : {}),
            ...sx,
          } as SxProps
        }
        {...props}
      />
    );
  }
);
