import { forwardRef } from "react";
import {
  DialogContentText as MUIDialogContentText,
  DialogContentTextProps as MUIDialogContentTextProps,
} from "@mui/material";

export type DialogContentTextProps = MUIDialogContentTextProps;

export const DialogContentText = forwardRef<HTMLDivElement, DialogContentTextProps>(
  (props, ref) => {
    return <MUIDialogContentText ref={ref} {...props} />;
  }
);
