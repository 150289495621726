import { ChangeEvent, FC, useState } from "react";
import clsx from "clsx";
import { DelayDisplay, DropdownItem } from "../ui";
import { NodeType, ClientScenarioPageDropdownChildDocument } from "../../types";
import { parseSolutionActionFromApi } from "../helpers/scenarioPageLoader/parseSolution";
import { HandleClickSolutionArgs } from "../helpers/node/handleClickSolution";
import {
  ApiScenarioPageChildCommon,
  ApiScenarioPageIntent,
  ApiScenarioPageSolution,
} from "../../types/api/scenarioVersionChildren";

export type DropdownItemBase = {
  title: string | null;
  name: string | null;
  id: string;
  type: NodeType;
};

export type Props = {
  childElements: ClientScenarioPageDropdownChildDocument[];
  className: string;
  delay: number;
  buttonText: string;
  placeholder: string;
  introduction: string;
  isActiveInTimetable: boolean;
  onSubmitIfSolution: (
    selectedNode: Omit<
      HandleClickSolutionArgs,
      "routerPush" | "shortCode" | "scenarioId" | "branch" | "dynamicUrls"
    >
  ) => void;
  onSubmitIfIntent: (selectedNode: ApiScenarioPageIntent) => void;
  currentIntegrationType: string;
};

const DropdownNode: FC<Props> = ({
  childElements,
  className,
  delay,
  buttonText,
  placeholder,
  introduction,
  onSubmitIfSolution,
  onSubmitIfIntent,
}) => {
  const [selected, setSelected] = useState<string | undefined>(undefined);

  if (!childElements || childElements.length === 0) return null;

  const allChildren = childElements.map(({ title, name, id }) => (
    <DropdownItem key={id} name={title ?? name} id={id} />
  ));
  const classNames = clsx("ich-dropdown", className);

  const handleSelectedNode = (childElements: ClientScenarioPageDropdownChildDocument[]) => {
    const selectedNode = childElements.filter(({ id }) => id === selected)[0];
    if (selectedNode) {
      if (selectedNode.type === NodeType.solution) {
        const action = parseSolutionActionFromApi(
          (selectedNode as ApiScenarioPageChildCommon & ApiScenarioPageSolution).action
        );
        onSubmitIfSolution({
          ...action,
          id: selectedNode.id,
          actionType: (selectedNode as ApiScenarioPageChildCommon & ApiScenarioPageSolution)
            .actionType,
        });
      } else if (selectedNode.type === NodeType.intent) {
        onSubmitIfIntent(selectedNode as ApiScenarioPageChildCommon & ApiScenarioPageIntent);
      } else {
        // eslint-disable-next-line
        console.error(`Invalid NodeType: ${selectedNode}`);
      }
    }
  };

  return (
    <DelayDisplay delay={delay} className={classNames}>
      <p className="ich-dropdown-introduction" data-testid="ich-dropdown-introduction">
        {introduction}
      </p>
      <div style={{ marginBottom: "15px" }}>
        <select
          className="ich-dropdown-select"
          data-testid="ich-dropdown-select"
          defaultValue="default"
          onChange={(e: ChangeEvent<HTMLSelectElement>) => {
            setSelected(e.target.value);
          }}
        >
          <option
            value="default"
            disabled
            className="ich-dropdown-select-option"
            data-testid="ich-dropdown-select-option"
          >
            {placeholder ?? "Select a choice/sélectionner un choix"}
          </option>
          {allChildren}
        </select>
      </div>
      {selected && (
        <div style={{ marginBottom: "15px" }}>
          <button
            className="ich-dropdown-button"
            data-testid="ich-dropdown-button"
            onClick={() => {
              handleSelectedNode(childElements);
            }}
          >
            {buttonText}
          </button>
        </div>
      )}
    </DelayDisplay>
  );
};

export default DropdownNode;
