import { Flag } from "@do/ui-flags";
import { SelectProps as MUISelectProps, SxProps } from "@mui/material";
import { forwardRef, useMemo } from "react";
import { Language } from "../../types/language";
import { Select } from "./Select";

export type LocaleDropdownProps = Pick<
  MUISelectProps<unknown>,
  "id" | "value" | "onChange" | "sx"
> & {
  locales: Language[];
};

const flagSxProps: SxProps = {
  lineHeight: "1.2em",
};

const availableLocales = [Language.en, Language.fr];

export const LocaleDropdown = forwardRef<HTMLDivElement, LocaleDropdownProps>(
  ({ locales, value, onChange, ...props }, ref) => {
    const localesLogos = useMemo(
      () =>
        availableLocales.map((locale) => ({
          label: <Flag immediatelyVisible={value === locale} locale={locale} sx={flagSxProps} />,
          value: locale,
        })),
      [value]
    );

    if (locales.length === 0 || !value || !onChange) {
      return null;
    }

    return (
      <Select
        formControlProps={{
          sx: {
            minWidth: "none",
            ".MuiSelect-select": {
              display: "flex",
              minHeight: "auto !important",
            },
          },
        }}
        onChange={onChange}
        options={localesLogos.filter(({ value }) => locales.includes(value))}
        ref={ref}
        value={value}
        {...props}
      />
    );
  }
);
