import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button } from "@mui/material";
import { FC } from "react";

export type Props = {
  onClick: () => void;
};

const BackLink: FC<Props> = ({ onClick }) => {
  return (
    <Button id="ich-back-button" type="button" onClick={onClick} sx={{ minWidth: 0 }}>
      <ArrowBackIcon id="ich-back-button-icon" />
      <Box
        id="ich-back-button-label"
        sx={{
          textTransform: "initial",
        }}
      >
        Retour
      </Box>
    </Button>
  );
};

export default BackLink;
