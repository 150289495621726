import { forwardRef } from "react";
import {
  TableFooter as MUITableFooter,
  TableFooterProps as MUITableFooterProps,
} from "@mui/material";

export type TableFooterProps = MUITableFooterProps;

export const TableFooter = forwardRef<HTMLTableSectionElement, TableFooterProps>((props, ref) => {
  return <MUITableFooter ref={ref} {...props} />;
});
