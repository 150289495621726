import { forwardRef } from "react";
import {
  TableSortLabel as MUITableSortLabel,
  TableSortLabelProps as MUITableSortLabelProps,
} from "@mui/material";

export type TableSortLabelProps = MUITableSortLabelProps;

export const TableSortLabel = forwardRef<HTMLSpanElement, TableSortLabelProps>((props, ref) => {
  return <MUITableSortLabel ref={ref} {...props} />;
});
