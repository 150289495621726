import { GREY_300 } from "../theme";

export const ButtonTogglePadding = {
  small: "4px 10px !important",
  medium: "6px 16px !important",
  large: "8px 22px !important",
};

const ButtonToggleBorderValue = `solid ${GREY_300} 1px !important`;

export const ButtonToggleBorder = {
  vertical: {
    borderTop: ButtonToggleBorderValue,
    borderBottom: ButtonToggleBorderValue,
  },
  horizontal: {
    borderLeft: ButtonToggleBorderValue,
    borderRight: ButtonToggleBorderValue,
  },
};
