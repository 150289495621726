import { parseUserAgent, UserAgentOs } from "../../common/userAgent";
import { SolutionActionType } from "../../types";
import { ActionApp, ActionStore } from "../../types/common";

export function getUrlAccordingToOs(
  actionType: SolutionActionType.app | SolutionActionType.store,
  action: ActionStore & ActionApp
) {
  if (actionType === SolutionActionType.app && action.os === "iOS") return action.iosLink;
  if (actionType === SolutionActionType.app && action.os === "Android") return action.androidLink;
  if (actionType === SolutionActionType.store && action.os === "iOS") return action.appStoreLink;
  if (actionType === SolutionActionType.store && action.os === "Android")
    return action.playStoreLink;

  return undefined;
}

export const getOsFromParsedUserAgent = (
  parsedUserAgent: ReturnType<typeof parseUserAgent>
): "iOS" | "Android" | "other" => {
  if (parsedUserAgent.os.type === UserAgentOs.Android) return "Android";
  if (parsedUserAgent.os.type === UserAgentOs.Ios) return "iOS";
  return "other";
};
