import { SolutionActionType } from "../../../types";

type CheckIfUrlActionTypeArg = {
  actionType: SolutionActionType;
};

export const checkIfUrlActionType = ({ actionType }: CheckIfUrlActionTypeArg) => {
  if (
    actionType === SolutionActionType.callback ||
    actionType === SolutionActionType.downloadDocument ||
    actionType === SolutionActionType.chatbot ||
    actionType === SolutionActionType.contactForm ||
    actionType === SolutionActionType.displayContent ||
    actionType === SolutionActionType.faq ||
    actionType === SolutionActionType.fax ||
    actionType === SolutionActionType.form ||
    actionType === SolutionActionType.customerArea
  ) {
    return true;
  }
  return false;
};
