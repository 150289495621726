import { Box } from "@mui/material";
import { BackLink, CloseButton, Menu } from "../molecules";
import { FC, memo, useEffect, useState } from "react";
import {
  STORAGE_HISTORY_IDX_KEY,
  ScenarioDetailsSettings,
  ScenarioNavigationItem,
  getInSessionStorage,
  setInSessionStorage,
} from "..";
import { NextRouter } from "next/router";

type Props = {
  header: string;
  navigation: ScenarioNavigationItem[];
  mainPage: { key: string; id: string };
  currentPageKey: string | undefined;
  settings: ScenarioDetailsSettings;
  router: NextRouter;
  onClickHomeButton: () => void;
  onClickBackButton: () => void;
  onClickCloseButton: () => void;
};

type ShowBackButtonArgs = {
  isBackButtonActive: boolean;
  mainPage: { key: string; id: string };
  currentPageKey?: string;
};

const showBackButton = ({ isBackButtonActive, mainPage, currentPageKey }: ShowBackButtonArgs) => {
  if (!isBackButtonActive) {
    return false;
  }
  let historyIdx = getInSessionStorage(STORAGE_HISTORY_IDX_KEY);
  if (!historyIdx) {
    setInSessionStorage(STORAGE_HISTORY_IDX_KEY, "0");
    historyIdx = "0";
  }
  return mainPage.key !== currentPageKey && historyIdx !== "0";
};

type ShowHomeButtonArgs = {
  onClickHomeButton: () => void;
  isBackButtonActive: boolean;
};
const showHomeButton = ({ onClickHomeButton, isBackButtonActive }: ShowHomeButtonArgs) => {
  const homeButton = document.querySelector<HTMLDivElement>("#ich-home");
  if (homeButton != null && !isBackButtonActive) {
    homeButton.addEventListener("click", onClickHomeButton);
    homeButton.style.display = "block";
  }
};

const Header: FC<Props> = memo(
  ({
    header,
    navigation,
    mainPage,
    currentPageKey,
    settings,
    router,
    onClickHomeButton,
    onClickBackButton,
    onClickCloseButton,
  }) => {
    const [showBackBtn, setShowBackBtn] = useState(false);

    const isBackButtonActive = settings.backButton || false;
    const isCloseButtonActive = settings.closeButton || false;

    const isInIframe = window.location !== window.parent.location;
    const showNavMenu = !isBackButtonActive && !isCloseButtonActive && navigation.length > 0;
    const showCloseButton = isCloseButtonActive;

    useEffect(() => {
      showHomeButton({ onClickHomeButton, isBackButtonActive });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      setShowBackBtn(showBackButton({ isBackButtonActive, mainPage, currentPageKey }));
    }, [currentPageKey, isBackButtonActive, mainPage]);

    return (
      <>
        {showCloseButton && isInIframe && <CloseButton onClickCloseButton={onClickCloseButton} />}
        {showNavMenu && (
          <Menu
            navigation={navigation}
            mainPage={mainPage}
            onClickHomeLink={onClickHomeButton}
            currentPageKey={currentPageKey}
          />
        )}
        <Box dangerouslySetInnerHTML={{ __html: header }} />
        {showBackBtn && <BackLink onClick={onClickBackButton} />}
      </>
    );
  }
);

Header.displayName = "Header";

export default Header;
