import { HTMLAttributeAnchorTarget, forwardRef } from "react";
import { Button as MUIButton, ButtonProps as MUIButtonProps } from "@mui/material";
import { LoadingButton as MUILoadingButton } from "@mui/lab";
import NextLink, { LinkProps as NextLinkProps } from "next/link";
import { ButtonColor, ButtonSize, ButtonVariant } from "../../types";
import { MUISizesConverter } from "../../constants";
import { getDoLinearGradient } from "../../doTheme";

export type ButtonProps = Omit<MUIButtonProps, "color" | "href" | "size" | "variant"> &
  Pick<NextLinkProps, "as" | "prefetch"> & {
    color?: ButtonColor;
    href?: NextLinkProps["href"];
    loading?: boolean;
    nextLink?: boolean;
    size?: ButtonSize;
    target?: HTMLAttributeAnchorTarget | undefined;
    variant?: ButtonVariant;
  };

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { as, color, href, loading = false, nextLink = true, prefetch, size, sx, variant, ...props },
    ref
  ) => {
    const commonProps: MUIButtonProps & {
      loading?: boolean;
    } = {
      color: color === "gradient" ? "inherit" : color,
      ref,
      size: MUISizesConverter[size ?? "md"],
      sx: {
        ...(color === "gradient" && {
          background: getDoLinearGradient(110),
          color: "white",
          "&:hover": {
            background: getDoLinearGradient("br"),
          },
        }),
        ...sx,
      },
      variant,
      ...props,
    };

    if (loading) {
      commonProps.loading = loading;
    }

    const ButtonComponent = loading ? MUILoadingButton : MUIButton;

    if (nextLink && href) {
      return (
        <NextLink href={href} as={as} prefetch={prefetch} passHref legacyBehavior>
          <ButtonComponent ref={ref} {...commonProps} />
        </NextLink>
      );
    }

    if (href) {
      if (typeof href !== "string") {
        throw new Error("Button created with non-string href");
      }

      commonProps.href = href;
    }

    return <ButtonComponent ref={ref} {...commonProps} />;
  }
);
