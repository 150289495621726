let sessionStorageIsAvailable: boolean | undefined = undefined;

const testStore: { [key: string]: string } = {};

export const isSessionStorageAvailable = () => {
  if (sessionStorageIsAvailable !== undefined) {
    return sessionStorageIsAvailable;
  }

  try {
    window.sessionStorage.setItem("test", "test");
    window.sessionStorage.removeItem("test");
    sessionStorageIsAvailable = true;
  } catch (e) {
    sessionStorageIsAvailable = false;
  }

  return sessionStorageIsAvailable;
};

export const getInSessionStorage = (key: string) => {
  const sessionStorageIsAvailable = isSessionStorageAvailable();

  if (typeof window !== "undefined") {
    if (sessionStorageIsAvailable) return sessionStorage.getItem(key);
    return testStore[key] ?? null;
  }

  return null;
};

export const setInSessionStorage = (key: string, value: string) => {
  const sessionStorageIsAvailable = isSessionStorageAvailable();

  if (sessionStorageIsAvailable) {
    sessionStorage.setItem(key, value);
  } else {
    testStore[key] = value;
  }
};
