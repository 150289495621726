type HandleUrlActionArgs = {
  url: string | undefined;
  openInCurrentTab?: boolean;
};

export const handleUrlAction = ({ url, openInCurrentTab }: HandleUrlActionArgs) => {
  if (url) {
    window.open(url, openInCurrentTab ?? false ? "_self" : "_blank");
    return true;
  } else {
    // eslint-disable-next-line
    console.error("No url provided");
    return false;
  }
};
